import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { ROUTER_PATHS } from "./constants/routerPaths";
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { setFaviconApi } from './features/auth/pageSlice';

import Sample from './pages/sample';
import Login from './pages/auth/login';
import Tree from './pages/tree'
import Join from './pages/auth/join';
import Reset from './pages/auth/reset';
import Loading from './components/loading/Loading';
import Vilage from './pages/Vilage';
import DashBoard from './pages/DashBoard';
import ProtectedRoute from './ProtectedRoute';
import MyInfo from './pages/MyInfo';
import MainPage from './pages/MainPage';
import DooriWalk from './pages/DooriWalk';
import LineTree from './pages/LineTree';
import Board from './pages/Board';
import BoardDetail from './pages/BoardDetail';
import OrderRequest from './pages/OrderRequest';
import OrderHistory from './pages/OrderHistory';
import UrlRefresh from './components/UrlRefresh';
import RequestWithdrawal from './pages/RequestWithdrawal';
import WithdrawalDetails from './pages/WithdrawalDetails';
import Mileage from './pages/Mileage';



const AppRoutes = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        const handlePopState = () => {
            window.location.reload();
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    const [favicon, setFavicon] = useState("https://walkes.s3.ap-northeast-2.amazonaws.com/favicon.png")
    const [ogImg, setOgImg] = useState("https://espero.co.kr/img/espero_ogimg.png")

    return (
        <Router>
            <UrlRefresh />
            <Helmet>
                <link rel="icon" href={favicon} type="image/png" />
                <meta property="og:image" content={ogImg}></meta>
            </Helmet>
            <Routes>
                <Route path={ROUTER_PATHS.VILAGE} element={<ProtectedRoute element={<Vilage />} />} />
                <Route path={ROUTER_PATHS.HOME} element={<Sample/>} />
                <Route path={ROUTER_PATHS.LOGIN} element={<Login/>} />
                <Route path={ROUTER_PATHS.JOIN} element={<Join/>} />
                {/* <Route path={ROUTER_PATHS.TREE} element={<Tree/>} /> */}
                <Route path={ROUTER_PATHS.RESET} element={<Reset/>} />
                <Route path={ROUTER_PATHS.MAINPAGE} element={<MainPage />} />
                <Route path={ROUTER_PATHS.MYINFO}  element={<ProtectedRoute element={<MyInfo />} />} />
                <Route path={ROUTER_PATHS.DOORIWALK}  element={<ProtectedRoute element={<DooriWalk />} />} />
                <Route path={ROUTER_PATHS.DASHBOARD} element={<ProtectedRoute element={<DashBoard />} />} />
                <Route path={ROUTER_PATHS.LINE_TREE} element={<ProtectedRoute element={<LineTree />} />} />
                <Route path={ROUTER_PATHS.BOARD} element={<ProtectedRoute element={<Board />} />} />
                <Route path={ROUTER_PATHS.BOARD_DETAIL} element={<ProtectedRoute element={<BoardDetail />} />} />
                <Route path={ROUTER_PATHS.ORDER_REQUEST} element={<ProtectedRoute element={<OrderRequest />} />} />
                <Route path={ROUTER_PATHS.ORDER_HISTORY} element={<ProtectedRoute element={<OrderHistory />} />} />
                <Route path={ROUTER_PATHS.REQUEST_WITHDRAWAL} element={<ProtectedRoute element={<RequestWithdrawal />} />} />
                <Route path={ROUTER_PATHS.WITHDRAWAL_DETAILS} element={<ProtectedRoute element={<WithdrawalDetails />} />} />
                <Route path={ROUTER_PATHS.MILEAGE} element={<ProtectedRoute element={<Mileage />} />} />
            </Routes>
        </Router>
    );
};

export default AppRoutes;