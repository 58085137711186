export const ERROR_CODE = {
    OK: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    INTERNAL_SERVER_ERROR: 500
};


export const ERROR_CODE_DETAIL = {
    INVALID_TOKEN: "40101",
    ACCESS_TOKEN_EXPIRED: "40103",
    REFRESH_TOKEN_EXPIRED: "40104"
};
