import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import pageReducer from '../features/auth/pageSlice';
import dashBoardReducer from './../features/dashBoardSlice';
import myInfoReducer from './../features/myInfoSlice';
import boardReducer from './../features/boardSlice';
import withdrawReducer from './../features/withdrawSlice';
import friendReducer from '../features/auth/friendSlice';
import nftReducer from '../features/nft/nftSlice';
import joinReducer from '../features/auth/joinSlice';
import mileageReducer from '../features/mileageSlice';

const store = configureStore({
    reducer: {
        auth: authReducer,
        join: joinReducer,
        page: pageReducer,
        dashboard : dashBoardReducer,
        myinfo : myInfoReducer,
        friend: friendReducer,
        board : boardReducer,
        withdraw : withdrawReducer,
        nft: nftReducer,
        mileage: mileageReducer
    },
});

export default store;