import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components';
import Footer from '../components/layout/Footer';
import { mobile, custom } from '../utils/mediaQueries';
import { Divider, useDeviceDetect } from '../components/utils/utils';
import Header from '../components/layout/Header';
import { nftListApi } from '../features/nft/nftSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/loading/Loading';
import STATUSES from '../constants/reduxStatus';
import Pagination from '../components/pagination/Pagination';
import { addCommasToNumber } from '../utils/utils';


const PageLayout = styled.div`

    

  width: 1290px;
  padding: 0;

  margin: 0 auto;
  box-sizing: border-box;

  ${custom(1290, `
      width: 100%;
      max-width: calc(1290px + 40px );
      padding: 0 20px;
      box-sizing: border-box;
  `)}

  display: flex; flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 100px;

  ${mobile `
    padding: 0 20px;
    font-size: 14px;
    margin-top: 60px;
  `}

  .order_history_container_wrap {
    width: 100%; 
  }

  .order_history_title h1 {
    
    font-size: 34px;
    font-weight: 700;
    text-align: center;
    color: #000;
    margin: 110px 0 40px 0;

    ${mobile`
        margin: 40px 0 30px 0;
        font-size: 20px;
        font-weight: 700;
    `}
  }

  h3.title {
    margin: 40px 0 16px 0;

    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    text-align: left;


    ${mobile`
        margin: 0 0 10px 0;
    `}
  }

  .user_input_wrap .user_input {
    max-width: 460px;
    ${mobile`max-width: 100%;`}
  }

`;

const GlobalStyle = createGlobalStyle`

    body {
        background-color: #FAFFF1;
    }

    .App{
        min-height: 100vh;
        display: flex; flex-direction: column;
        justify-content: space-between;
        overflow-x: hidden;
    }
`;

const Items = styled.div`

    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    
    gap: 20px 12px;
    padding: 20px 15px;

    ${mobile`
        grid-template-columns: 1fr 1fr;
        padding: 10px 5px;
        gap: 12px 10px;
    `}

    .item{
        /* width: 280px; */
        cursor: pointer;
        background-color: #fff;
        border-radius: 20px;
        box-shadow: 0px 4px 13px 0px #0000001A;
        padding: 16px 16px 0 16px;
        ${mobile`
            padding: 7px 7px 0 7px;
            border-radius: 10px;
        `}

        display: flex; flex-direction: column;

        .item-detail{
            
        }
        
        .img-wrap{

            position: relative;
            width: 100%;

            img{
                width: 100%;
                border-radius: 15px;
                ${mobile`border-radius: 10px;`}
            }

            .amount{
                position: absolute;
                display: flex; align-items: center;
                justify-content: center;

                right: 0; bottom: 0;
                margin: 12px;
                border-radius: 10px;
                background-color: #fff; color: #222222;
                
                /* width: 24px; height: 24px; */
                padding: 5px 10px;
                font-size: 16px;
                text-align: center;
                box-shadow: 0px 0px 10px 0px #0000001A;
            }

        }

        .item-detail{

            ${mobile`padding: 0 5px`}

            .date{

                margin-top: 10px;
                font-size: 16px; color: #666;
                font-weight: 400;
                /* text-align: center; */
                text-transform: uppercase;

                }

                .desc{
                width: 100%;
                margin: 10px 0 20px 0;
                display: flex;
                justify-content: space-between;


                font-size: 16px; color: #666;
                font-weight: 400;
                text-align: center;
                text-transform: uppercase;


                ${mobile`margin: 10px 0; gap: 2px;`}

                .type{



                }

                .nft-id{


                    font-size: 18px;
                    ${mobile`
                        font-size: 16px;
                    `}
                    font-weight: 700;
                    text-align: center;
                    color: #16BFB6;

                    }

                }
        }




    }
`;

const ViewMoreBtn = styled.div`

    margin: 60px auto 119px auto;
    ${mobile` margin: 40px 0;`}
    display: flex; justify-content: center;
    align-items: center;

    button{
        display: flex; align-items: center;
        justify-content: center;
        padding: 15px 22px 15px 22px;
        border-radius: 50px;
        font-family: Roboto;

        width: 171px; height: 53px;

        font-size: 20px;
        font-weight: 700;
        text-align: center;
        background-color: #fff; color: #16BFB6;
        box-shadow: 0px 0px 10px 0px #0000001A;
        ${mobile`
            display: flex; justify-content: center;
            align-items: center;
            width: 148px;
            height: 43px;

            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;
            text-align: center;
        `}

        img{
            width: 19px; height: 10px;
            margin-left: 12px;

            ${mobile`width: 15px; height: 7px;`}
        }

    }
`;

const NoNftBox = styled.div`
    width: 100%;
    height: 441px;
    display: flex; justify-content: center;
    align-items: center;

    color: #666;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    ${mobile`height: 379px;`}
`;

const EndDivider = styled.div`
    width: 100%;
    border-bottom: ${p => p.$isThick ? '2px solid #666' : '1px solid #d9d9d9'};
    /* margin: 0 0 119px 0;
    ${mobile`margin: 0 0 40px 0;`} */
`;

const EndDividerWrap = styled.div`
    /* margin-bottom: 107px; */
    ${mobile`margin-bottom: 77px;`}
`;

function OrderHistory() {

    const dispatch = useDispatch();

    const data = useSelector((state) => state.nft.data.myNft);

    // const data = { // 테스트 데이터
    //     content: [
    //         {
    //             nftImageUrl: 'img/nft_member.png',
    //             orderPrice: '4500',
    //             productName: 'Member',
    //             orderCode: '131',
    //             createdAt: '2000-01-01T00:00:00',

    //         }
    //     ]
    // }

    const status = useSelector((state) => state.nft.status)


    const [pageNum, setPageNum] = useState(1);

    const [mobileList, setMobileList] = useState([])


    const fetchData = async (page) => {
        try {
            setPageNum(page);
            const result = await dispatch(nftListApi({ page: page, pageSize: 8 }));
            const content = result.payload.content;
        
            if (page === 1) {
                setMobileList(content);
            } else {
                setMobileList(prevList => [...prevList, ...content]);
            }
        } catch (error) {
          console.log("구매내역 불러오는 중 오류 발생", error);
        }
    };

    const isMobile = useDeviceDetect();

    useEffect(() => { // 첫 로딩, pageNum 바뀔때 fetch
        fetchData(pageNum)
    }, [pageNum]);

    useEffect(() => { // 기기 전환 될때마다 1페이지로 초기화
        setPageNum(1);
    }, [isMobile])

    // useEffect(() => {
    //     console.log(mobileList)
    // }, [mobileList])

    
  return (
    <>
    {status === STATUSES.LOADING && <Loading />}
    <Header />
    <GlobalStyle />

    <PageLayout className='order_history_container'>
        <div className='order_history_container_wrap'>
            <div className='order_history_title'>
                <h1>NFT 구매내역</h1>
            </div>
            <div className='user_input_wrap'>

                <h3 className='title'>NFT 구매내역</h3>

                <Divider $isThick={true} />

                {data.content?.length === 0 ?
                <>
                    <NoNftBox>
                        <p>{status === STATUSES.LOADING ? '' : '구매한 내역이 없습니다.'}</p>
                    </NoNftBox>
                </>
                :
                <Items className='nft_items'>

                    {
                        
                    (isMobile ? mobileList : data.content).map((nft, idx) => {
                        return (
                            <div className='item' key={idx}>
                                <div className='img-wrap'>
                                    <img src={nft.nftImageUrl} alt='ntf-image' />
                                    <p className='amount'>
                                        {addCommasToNumber(nft.orderPrice)}
                                        <span style={{color: '#666666', marginLeft: '5px'}}> ESP</span>
                                    </p>
                                </div>

                                <div className='item-detail'>
                                    <div className='date'>{
                                        nft.createdAt.split(' ')[0]
                                        }</div>
                                    <div className='desc'>
                                        <p className='type'>{nft.productName}</p>
                                        <div className='nft-id'>#{nft.orderCode}</div>
                                        
                                    </div>
                                </div>

                                
                                
                            </div>
                        )
                    })

                    
                    
                    }

                </Items>
                    
                }
                {
                    !isMobile &&
                    <EndDividerWrap>
                        <EndDivider />
                    </EndDividerWrap>
                }
                {
                    isMobile && data.totalPages <= pageNum &&
                    <EndDividerWrap>
                        <EndDivider />
                    </EndDividerWrap>
                }
                <Pagination data={data?.content && data} onChange={(num) => setPageNum(num)} putPageNum={pageNum} />

                {/* {
                    myNft.length / perView > pageIndex ?
                        <ViewMoreBtn className='view-more-btn'>
                            <button onClick={() => setPageIndex(pageIndex + 1)}>
                                View more
                                <img src='/img/viewmore_ico.svg' alt='viewmore-btn' />
                            </button>
                        </ViewMoreBtn>
                    :
                        <EndDivider />

                } */}
            </div>

        </div>
        
    </PageLayout>
    <Footer style={{marginTop: 'auto'}}/>

    </>
  )
}

export default OrderHistory