import apiClient from './apiClient';
import log from 'loglevel';
export const apiCall = async (url, method, data,  headers, rejectWithValue) => {

    try {
        log.info(`REQUEST INFO -> , URL: ${url}, METHOD: ${method}`);
        
        let getParam = {
            params: data
        }

        if(headers != undefined){
            getParam = {...getParam, headers}
        }

        if(method.toUpperCase() === 'GET') {
            const response = await apiClient(url, getParam )
            return response.data.data;
        } else {
            let headers = {};
            let requestData;

            // if(isFormData){
            //     headers['content-type'] = 'multipart/form-data';
            //     requestData = new FormData()

            //     for(const key in data){
            //         requestData.append(key, data[key])
            //     }
            // }else {
                headers['content-type'] = 'application/json';
                requestData = JSON.stringify(data)
            // }
            const response = await apiClient({
                url,
                method,
                headers,
                data: requestData
            });
            return response.data.data;
        }
           
    } catch (err) {
        if (!err.response) {
            return rejectWithValue({
                message: '네트워크에 문제가 발생하였습니다. 잠시 후 다시 시도해주세요.'
            });
        }
        return rejectWithValue({
            message: err.response.data.response_message || '서버에 문제가 발생하였습니다. 잠시 후 다시 시도해주세요.'
        });
    }
};
