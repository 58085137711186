import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from "../utils/apiThunk";
import REDUX_STATUS from "../constants/reduxStatus";
import API_URL from "../constants/apiUrl";
import log from 'loglevel';

export const dashBoardApi = createApiThunk('dashboard/basic', API_URL.DASHBOARD, 'get');

const dashBoardSlice = createSlice({
    name: 'dashboard',
    initialState: {
        status: 'idle',
        error: null,
        data: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(dashBoardApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(dashBoardApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(dashBoardApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            });
    },
});

export default dashBoardSlice.reducer;
