import React, { useEffect, useState } from 'react'
import styled, { createGlobalStyle }  from 'styled-components'
import { custom, mobile } from '../utils/mediaQueries';
import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import { mileageListApi } from '../features/mileageSlice';
import { useDispatch, useSelector } from 'react-redux';
import Loading from '../components/loading/Loading';
import STATUSES from '../constants/reduxStatus';
import Pagination from '../components/pagination/Pagination';
import { useDeviceDetect } from '../components/utils/utils';
import { addCommasToNumber } from '../utils/utils';
import { Divider } from '../components/utils/utils';

const PageLayout = styled.div`

    width: 1290px;
    margin: 100px auto 0 auto;

    ${custom(1290, `
        width: 100%;
        max-width: calc(1290px + 40px);
        padding: 0 20px;
        box-sizing: border-box;
    `)}

    ${mobile`
        margin: 60px auto 0 auto;
    `}

`;

const PageTitle = styled.h1`
    font-family: Roboto;
    font-size: 34px;
    font-weight: 700;
    text-align: center;
    color: #000000;
    margin: 110px 0 40px 0;

    ${mobile`
        font-family: Roboto;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        margin: 40px 0 30px 0;
    `}
`;

const TableTitle = styled.h2`
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    margin: 0 0 16px 0;
    color: #000000;

    ${mobile`
        font-family: Roboto;
        font-size: 18px;
        font-weight: 700;
        text-align: left;

        margin: 0 0 10px 0;
    `}
`;

const TableLayoutPC = styled.div`
    width: 100%;
    border-top: 2px solid #666;
    display: block;

    ${mobile`
        display: none;
    `}

    ul.row-content{
        /* display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; */
        display: flex;
        align-items: center;
        height: 51px;
        padding: 0 15px;
        
        border-bottom: 1px solid #d9d9d9;

        color: #222;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        

        &:first-child{

            color: #000;
            text-align: center;
            font-family: Roboto;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;

        }

        li{

            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            /* width: 100%; */
            /* margin-right: 20px; */

            &:nth-child(1){ width: 65px; margin-right: 25px }
            &:nth-child(2){ width: 380px; margin-right: 15px }
            &:nth-child(3){ width: 380px; margin-right: 15px }
            &:nth-child(4){ width: 380px }

        }
    }
        
    
`;

const TableLayoutMobile = styled.div`
    width: 100%;
    border-top: 2px solid #666;
    display: none;

    .content-wrap{

        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #d9d9d9;
        height: 83px;
        padding: 15px 5px;

        

        ul{
            li{
                /* padding-bottom: 12px; */
                
                ${mobile`
                    color: #222;
                    font-family: Roboto;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                `}
            }
            
        }

        ul.row-title{
            display: flex; flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            color: #222;
            text-overflow: ellipsis;
            font-family: Roboto;
            font-size: 16px;
            font-weight: 700;

            li:last-child{
                overflow: hidden;
                color: #222;
                text-overflow: ellipsis;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        ul.row-content{

            display: flex; flex-direction: column;
            text-align: right;
            align-items: flex-end;
            justify-content: space-between;

        }
    }

    ${mobile`
        display: block;
    `}

`;

const NoNftBox = styled.div`
    width: 100%; height: 441px;
    display: flex; justify-content: center;
    align-items: center;

    color: #666;
    text-align: center;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    ${mobile`height: 379px;`}
`;

const GlobalStyle = createGlobalStyle`
    body {
        background-color: #FAFFF1;
    }

    .App{
        min-height: 100vh;
        display: flex; flex-direction: column;
        justify-content: space-between;
    }
`;


function Mileage() {

    const dispatch = useDispatch();

    const [pageNum, setPageNum] = useState(1);
    const [mobileList, setMobileList] = useState([])
    const isMobile = useDeviceDetect();


    const { data, status } = useSelector((state) => state.mileage)

    // useEffect(() => {
    //     console.log('data : ', data)
    // }, [data])

    const fetchData = async (page) => {
        try {
            setPageNum(page);
            const result = await dispatch(mileageListApi({ page: page, pageSize: 10 }));
            const content = result.payload.content;
        
            if (page === 1) {
                setMobileList(content);
            } else {
                setMobileList(prevList => [...prevList, ...content]);
            }
        } catch (error) {
          console.log("마일리지 내역 불러오는 중 오류 발생", error);
        }
    };

    useEffect(() => { // 첫 로딩, pageNum 바뀔때 fetch
        fetchData(pageNum)
    }, [dispatch, pageNum]);

    useEffect(() => { // 기기 전환 될때마다 1페이지로 초기화
        setPageNum(1);
    }, [isMobile])

    // useEffect(() => {
    //     console.log(data)
    // }, [data])



  return (
    <>
        {status === STATUSES.LOADING && <Loading />}
        <GlobalStyle />
        <Header />
        <PageLayout className='page-layout'>
            <PageTitle className='page-title'>
                Mileage Details
            </PageTitle>
            <TableTitle className='table-title'>
                Details
            </TableTitle>

            {data.content?.length === 0 ?
            <>
                <Divider $isThick={true}/>
                <NoNftBox>
                    <p>{status === STATUSES.LOADING ? '' : '마일리지 내역이 없습니다.'}</p>
                </NoNftBox>
                <Divider />
                    
            </>
            :
        <>
            <TableLayoutPC className='table-container-pc'>
                        <ul className='row-content'>
                            <li>NO</li>
                            <li>Date</li>
                            <li>Type</li>
                            <li>ESP</li>
                        </ul>
                {data.content?.map((content, index) => {
                    return(
                        status === STATUSES.SUCCEEDED && <ul className='row-content' key={index}>
                            <li>{data.totalElements - (data.size * (pageNum - 1)) - index}</li>  
                            <li>{content.createdAt}</li>
                            <li>{content.action}</li>
                            <li>{addCommasToNumber(content.points.toFixed(4))}</li>
                        </ul>
                    )
                })}
            </TableLayoutPC>
            <TableLayoutMobile className='table-container-mobile'>
                {mobileList.map((content, index) => {

                    return ( 
                        <div key={index} className='content-wrap'>
                            <ul className='row-title'>
                                <li>{content.createdAt}</li>
                                <li>ESP</li>
                            </ul>
                            <ul className='row-content'>  
                                <li>{content.action}</li>
                                <li>{addCommasToNumber(content.points.toFixed(4))}</li>
                            </ul>
                        </div>

                    )
                })}
            </TableLayoutMobile>
        </>
            
        }

            <Pagination
                data={data?.content && data}
                onChange={(num) => setPageNum(num)}
                putPageNum={pageNum}
            />
            
        </PageLayout>

        <Footer />
    </>
  )
}

export default Mileage