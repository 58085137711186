import React, { useEffect } from 'react';
import Header from '../components/layout/Header';
import styled from 'styled-components';
import Footer from '../components/layout/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { boardDetailApi } from '../features/boardSlice';
import { addCommasToNumber } from '../utils/utils';

const LayoutBg = styled.div`
    width: 100%;
    background-color: #FAFFF1;
    padding: 100px 20px 0px;
    min-height: 100vh;
    @media (max-width:780px){
        padding: 60px 20px 0px;
    }
`

const MainLayout = styled.div`
    max-width: 1290px;
    margin: 0 auto;
`

const MainTitle = styled.div`
    padding-top: 110px;
    text-align: center;
    font-weight: 700;
    font-size: 2.125rem;
    line-height: 2.49rem;
    @media (max-width:780px){
        padding-top: 40px;
        font-size: 1.25rem;
        line-height: 1.465rem;
    }
`

const ContentWrap = styled.div`
    margin: 58px 0px 63px 0px;
    width: 100%;
    @media (max-width:780px){
        margin: 30px 0px 5px 0px;
    }
`

const ContentTitle = styled.div`
    padding-bottom: 15px;
    border-bottom: 2px solid #666666;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 21px;
    p{
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.318rem;
        max-width: 1216px;
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
    }
    img{
        width: 28px;
        height: 28px;
        cursor: pointer;
    }
    @media (max-width:780px){
        gap: 30px;
        padding-bottom: 12px;
        p{
            font-size: 1rem;
            line-height: 1.172rem;
        }
        img{
            width: 20px;
            height: 20px;
        }
    }
`

const ContentHeader = styled.div`
    padding: 15px 15px 15px 10px;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    @media (max-width:780px){
        padding: 12px 5px;
    }
`

const ContentAuthor = styled.div`
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.172rem;
    @media (max-width:780px){
        font-size: 0.875rem;
        line-height: 1.026rem;
    }
`

const ContentHeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const ContentDate = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #666666;
  min-width: 85px;
`

const ContentView = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.172rem;
  color: #666666;
  min-width: 84px;
  position: relative;
  &::after{
    position: absolute;
    content: "";
    width: 18px;
    height: 1px;
    background-color: #D9D9D9;
    transform: rotate(-90deg) translateY(-50%);
    top: 49%;
    left: -12px;
  }
`

const ContentDetail = styled.div`
    padding: 25px 15px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
    color: #666666;
    border-bottom: 1px solid #D9D9D9;
    min-height: 266px;
    @media (max-width:780px){
        padding: 12px 5px;
        min-height: 386px;
        font-size: 0.875rem;
        line-height: 1.026rem;
    }
`

const ContentDownContainer = styled.div`
    padding: 14px 15px 0px 0px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width:780px){
        padding: 14px 7px 0px 0px;
    }
`

const ContentDownBox = styled.div`
    background-color: #F7F7F7;
    padding: 8px 10px;
    border-radius: 12px;
    display: flex;
    gap: 10px;
    cursor: pointer;
    @media (max-width:780px){
        border-radius: 5px;
        padding: 6px 10px;
    }
`

const DownTxt = styled.div`
    font-size: 0.875rem;
    line-height: 1.026rem;
    color: #666;
`

const DownIcon = styled.div`
    width: 16px;
    height: 16px;
    @media (max-width:780px){
        width: 14px;
        height: 14px;
    }
    img {
        width: 100%;
    }
`

const ContentNextPrevBox = styled.div`
    padding: 15px 20.5px;
    border-bottom: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width:780px){
        padding: 12px 5.5px;
    }
`

const ContentBox = styled.div`
    min-width: 50px;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
    color: #666666;
    @media (max-width:780px){
        font-size: 0.875rem;
        line-height: 1.026rem;
    }
`

const ContentBoxTitle = styled.div`
    max-width: 1023px;
    width: 100%;
    text-align: center;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
    color: #222222;
    cursor: pointer;
    @media (max-width:780px){
        font-size: 0.875rem;
        line-height: 1.026rem;
    }
`

const ContentBoxDate = styled.div`
    min-width: 85px;    
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
    color: #222222;
    @media (max-width:780px){
        display: none;
    }
`

function BoardDetail() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { status, error, data } = useSelector((state) => state.board);
    const { postId } = location.state;

    // 이전글 클릭
    const handlePrevDetail = (prevPostId) => {
        navigate('/boardDetail', { state: { postId: prevPostId } });
    };

    // 다음글 클릭
    const handleNextDetail = (nextPostId) => {
        navigate('/boardDetail', { state: { postId: nextPostId } });
    };

    useEffect(()=>{
        dispatch(boardDetailApi({postId : postId}));
    },[postId]);

    return (
        <>
            <Header />
            <LayoutBg>
                <MainLayout>
                    <MainTitle>공지사항</MainTitle>
                    <ContentWrap>
                        <ContentTitle>
                            <p>{data?.post?.postTitle ? data?.post?.postTitle : ""}</p>
                            <img onClick={()=>{navigate('/board')}} src='./../img/list_ico.png' />
                        </ContentTitle>
                        <ContentHeader>
                            <ContentAuthor>{data?.post?.userNickName ? data?.post?.userNickName : "-"}</ContentAuthor>
                            <ContentHeaderRight>
                                <ContentDate>{data?.post?.postDateTime ? data?.post?.postDateTime : "-"}</ContentDate>
                                <ContentView>조회 {data?.post?.postHit ? addCommasToNumber(data?.post?.postHit) : "-"}</ContentView>
                            </ContentHeaderRight>
                        </ContentHeader> 
                        {
                            data?.post?.postParam1 &&
                            <ContentDownContainer>
                                <ContentDownBox onClick={()=>window.location.href = data?.post?.postParam1}>
                                    <DownTxt>첨부파일</DownTxt>
                                    <DownIcon>
                                        <img src='./../img/download_ico.png' alt="Download Icon" />
                                    </DownIcon>
                                </ContentDownBox>
                            </ContentDownContainer>
                        }
                        <ContentDetail>
                            {data?.post?.postContent ? (<div dangerouslySetInnerHTML={{ __html: data.post.postContent }} />) : ("")}
                        </ContentDetail>
                        {
                            data?.prev &&
                            <ContentNextPrevBox>
                                <ContentBox>이전글</ContentBox>
                                <ContentBoxTitle onClick={() => handlePrevDetail(data.prev.postId)}>{data?.prev?.postTitle ? data?.prev?.postTitle : ""}</ContentBoxTitle>
                                <ContentBoxDate>{data?.prev?.postDateTime ? data?.prev?.postDateTime : ""}</ContentBoxDate>
                            </ContentNextPrevBox>
                        }
                        {
                            data?.next &&
                            <ContentNextPrevBox>
                                <ContentBox>다음글</ContentBox>
                                <ContentBoxTitle onClick={() => handleNextDetail(data.next.postId)}>{data?.next?.postTitle ? data?.next?.postTitle : ""}</ContentBoxTitle>
                                <ContentBoxDate>{data?.next?.postDateTime ? data?.next?.postDateTime : ""}</ContentBoxDate>
                            </ContentNextPrevBox>
                        }
                    </ContentWrap>
                    <Footer />
                </MainLayout>
            </LayoutBg>
        </>
    )
}

export default BoardDetail