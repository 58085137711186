import React, {useEffect} from 'react';
import AppRoutes from './routes';
import log from "loglevel";

log.setLevel('error');
function App() {
    useEffect(() => {
        log.info('BASE URL : ', process.env.REACT_APP_API_URL)
    }, []);

  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
