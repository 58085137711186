// src/features/user/userSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from "../../utils/apiThunk";
import REDUX_STATUS from "../../constants/reduxStatus";
import API_URL from "../../constants/apiUrl";
import log from 'loglevel';

export const nftPageApi = createApiThunk('ntf/page', API_URL.NFT_PURCHASE_PAGE, 'get');
export const nftApplyApi = createApiThunk('ntf/apply', API_URL.NFT_PURCHASE_APPLY, 'post');
export const nftPurchaseApi = createApiThunk('ntf/purchase', API_URL.NFT_PURCHASE, 'post');
export const nftListApi = createApiThunk('ntf/list', API_URL.NFT_PURCHASE_LIST, 'get', {'X-Client-Type':'web'});

const nftSlice = createSlice({
    name: 'auth',
    initialState: {
        data: {
            totalOwned: null,
            product: [
                {
                    id: 1,
                    productName: "",
                    productPrice: 0
                }
            ],
            myNft: {
                content: [],
                totalPages: 0,
                totalElements: 0
            }
        },
        status: 'idle',
        error: null,
    },
    reducers: {
        // setError(state, action) {
        //     state.error = action.payload;
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(nftPageApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(nftPageApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                
                // 보유 잔액, nft 상품 정보 업데이트
                state.data.totalOwned = action.payload.보유수량
                state.data.product = action.payload.product
            })
            .addCase(nftPageApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
                alert(action.payload.message);
            })
            .addCase(nftApplyApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(nftApplyApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
            })
            .addCase(nftApplyApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
                alert(action.payload.message);
            })
            .addCase(nftPurchaseApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(nftPurchaseApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
            })
            .addCase(nftPurchaseApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
                alert(action.payload.message);
            })
            .addCase(nftListApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(nftListApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;

                // nft 구매내역 업데이트
                state.data.myNft = action.payload
            })
            .addCase(nftListApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
                alert(action.payload.message);
            })
            
    },
});

export const { } = nftSlice.actions;

export default nftSlice.reducer;
