import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components';
import { useOutsideClick } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setLanguage } from '../../features/auth/pageSlice';

const LanguageSelect = styled.ul`

  position: relative;
  height: 100%;
  color: black;
  text-transform: uppercase;
  z-index: 1;

  color: #151515;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;


  li{
    width: auto;
    display: block;
    position: relative;
  }

  .current-language{
    position: relative;
    display: flex; align-items: center;

    .lang-options-item{
      width: 21px;
      cursor: pointer;
    }

    .arrow{
      position: relative;
      width: 12px; height: 9px;
      margin-left: 4px;
      top: -1px;
      transform: rotate(${p => p.$isOpen ? '180deg' : '0deg'});
      cursor: pointer;
    }
  }

  .lang-options{
    position: absolute;
    display: flex; flex-direction: column;
    cursor: pointer;
  }
`;

function LanguageSelector(props) {

  const { langList=['kr', 'us', 'jp'], ...rest } = props

  const currentLanguage = useSelector((state) => state.page.data.currentLanguage);

  const dispatch = useDispatch();

  const [isOpenLangSelector, setIsOpenLangSelector] = useState(false)

  const langOptionRef1 = useRef()
  const langOptionRef2 = useRef()

  useOutsideClick(() => {
    setIsOpenLangSelector(false)
  }, [langOptionRef1, langOptionRef2])

  return (

    <LanguageSelect className="langList" $isOpen={isOpenLangSelector} {...rest}>
        <li
            className='current-language'
            ref={langOptionRef1}
        >
            <span
              className='lang-options-item'
              onClick={() => setIsOpenLangSelector(!isOpenLangSelector)}
            >{currentLanguage}</span>
            <div onClick={() => setIsOpenLangSelector(!isOpenLangSelector)} style={{cursor: 'pointer'}}>
                <svg className='arrow'width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.79241 5.97063C5.3921 6.49064 4.6079 6.49064 4.20759 5.97063L1.04322 1.85999C0.537026 1.20243 1.00579 0.249999 1.83563 0.249999L8.16437 0.25C8.99421 0.25 9.46298 1.20242 8.95678 1.85999L5.79241 5.97063Z" fill="#151515"/>
                </svg>
            </div>

        </li>
        {
        <ul className='lang-options' ref={langOptionRef2}>
            {isOpenLangSelector && langList.filter((lang) => lang !== currentLanguage).map((lang, index) => {
            return (
                <li key={index}>
                <span className='lang-options-item' onClick={() => {
                    dispatch(setLanguage(lang));
                    setIsOpenLangSelector(false);
                }}>{lang}</span>
                </li>
            )
        })}
        </ul>}
    </LanguageSelect>

  )
}

export default LanguageSelector