// src/features/user/userSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from "../../utils/apiThunk";
import REDUX_STATUS from "../../constants/reduxStatus";
import API_URL from "../../constants/apiUrl";
import log from 'loglevel';

export const login = createApiThunk('auth/login', API_URL.LOGIN, 'post');
export const tree = createApiThunk('auth/tree', API_URL.TREE, 'get');
export const reset = createApiThunk('auth/reset', API_URL.RESET, 'post')

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'idle',
        error: null,
        data: null,
    },
    reducers: {
        logout(state) {
            state.status = REDUX_STATUS.IDLE;
            // document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            // document.cookie = 'refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        },
        setError(state, action) {
            state.error = action.payload;
            
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(login.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                localStorage.setItem('accessToken', action.payload?.accessToken);
                localStorage.setItem('refreshToken', action.payload?.refreshToken);
                state.status = REDUX_STATUS.SUCCEEDED;
            })
            .addCase(login.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            })
            .addCase(tree.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(tree.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(tree.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload;
            })
            .addCase(reset.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(reset.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
            })
            .addCase(reset.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            });
    },
});

export const { logout, setError } = authSlice.actions;

export default authSlice.reducer;
