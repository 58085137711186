import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const NavContainer = styled.div`
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    box-sizing: border-box;
    max-width: 1000px;
    width: 100%;
    padding-top: 66px;
    z-index: 11;
    @media (max-width:1040px){
        padding: 20px 20px 0px 20px;
    }
`

const NavLogo = styled.div`
    cursor: pointer;
    img{
        width: 100%;
        @media (max-width:780px){
            max-width: 146px;
            /* height: 48px; */
        }
    }
`

const MainHeaderWrap = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
    img{
        cursor: pointer;
        &:first-child{
            width: 144px;
        }
        width: 162px;
        height: 48px;
        @media (max-width:1040px){
            display: none;
        }
    }
`

const MyOfficeBtn = styled.div`
    /* max-width: 123px; */
    min-width: 123px;
    width: 100%;
    padding: 12px 0px;
    background-color: #16BFB6;
    border-radius: 5px;
    cursor: pointer;
    color: #FFFFFF;
    font-size: 1.125rem;
    line-height: 1.5rem;
    text-align: center;
    font-weight: 700;
`

function MainHeader({activeIndex}) {

    const navigate = useNavigate();

    const handleMyoffice = () => {
        navigate('/dashBoard');
    };

    const handleLogo = () => {
        navigate('/dashBoard');
    };

    const hadleDownload = () => {
        alert("준비 중입니다.");
    };

    return (
        <NavContainer>
            <NavLogo onClick={handleLogo}>
                <img src='./../img/logo_espero.png' />
            </NavLogo>
            <MainHeaderWrap>
                {
                    activeIndex !== 0 && 
                    <>
                        <img src='./../img/down_app.png' onClick={hadleDownload} />
                        <img src='./../img/down_google.png' onClick={hadleDownload}  />
                    </>
                }
                <MyOfficeBtn onClick={handleMyoffice}>마이오피스</MyOfficeBtn>
            </MainHeaderWrap>
        </NavContainer>
    )
}

export default MainHeader