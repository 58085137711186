import React, { useState , useEffect , useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Mousewheel, Pagination } from 'swiper/modules';
import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';
import MainHeader from '../components/layout/MainHeader';

const LayoutBg = styled.div`
    ::-webkit-scrollbar {
        display: none;
    }
    background-color: #FAFFF1;
    font-family: "NotoSansKR";
`

const Section1Wrap = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 143px;
    @media (max-width:1040px){
        align-items: start;
        height: 100%;
        padding: 100px 20px 0px 20px;
    }
`

const Section1 = styled.div`
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
`

const Section2Container = styled.div`
    display: flex;
    justify-content: space-between;
    img{
        width: 100%;
    }
    @media (max-width:780px){
        flex-wrap: wrap;
    }
`

const Section1Box = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width:1040px){
        flex-wrap: wrap;
    }
`

const Section1Title = styled.div`
    font-weight: 700;
    p{
        font-size: 1.5rem;
        line-height: 2.172rem;
    }
    span{
        font-size: 3.75rem;
        line-height: 5.43rem;
    }
    @media (max-width:1040px){
        p{
            font-size: 1rem;
            line-height: 1.448rem;
        }
        span{
            font-size: 2.5rem;
            line-height: 3.62rem;
        }
    }
`

const Section2Title = styled(Section1Title)`
    padding-top: 143px;
    white-space: nowrap;
    @media (max-width:1040px){
        padding-top: 40px;
    }
`

const Section1SubTitle = styled.div`
    padding-top: 30px;
    font-weight: 400;
    p{
        font-size: 2rem;
        line-height: 2.896rem;
        white-space: nowrap;
    }
    @media (max-width:1040px){
        padding-top: 10px;
        margin-bottom: 40px;
        p{
            font-size: 1.25rem;
            line-height: 1.81rem;
        }
    }
`

const Section1DownText = styled.div`
    font-size: 1rem;
    line-height: 1.448rem;
    margin-top: 62px;
    margin-bottom: 20px;
    @media (max-width:1040px){
        display: none;
    }
`

const Section1LeftImgBox = styled.div`
    display: flex;
    gap: 20px;
    img{
        cursor: pointer;
    }
    @media (max-width:1040px){
        display: none;
    }
`

const Section7Wrap = styled.div`
    height: 100vh;
    padding: 0 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    @media (max-width:1040px){
        height: calc(100vh - 87px);
    }
    @media (max-width:780px){
        height: calc(100vh - 73px);
    }
`

const Section7Title = styled.p`
    width: 100%;
    font-family: 'Roboto';
    font-size: 2.5rem;
    line-height: 2.93rem;
    font-weight: 600;
    text-align: center;
    background: linear-gradient(90.59deg, #16BFB6 36.97%, #A6FF1B 50.54%, #16BFB6 90.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width:1040px){
        text-align: left;
        margin-bottom: 30px;
    }
`

const Section7Footer = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    p{
        padding: 60px 20px;
        font-size: 1.125rem;
        line-height: 1.629rem;
        text-align: center;
        font-weight: 400;
    }
    @media (max-width:1040px){
        position: relative;
        width: 100%;
        p{
            text-align: left;
            font-size: 0.875rem;
            padding : 60px 0px 19px 0px
        }
    }
`

const Section7TxtBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: absolute;
    bottom: 18%;
    width: 100%;
    text-align: center;
    div{
        width: 320px;
        p{
            font-weight: 400;
            font-size: 1.125rem;
            line-height: 1.629rem;
        }
        span{
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2.172rem;
        }
    }
    @media (max-width:1040px){
        text-align: left;
        position: relative;
        bottom: 0;
        div{
            width: 100%;
            margin-top: 20px;
            p{
                font-size: 0.875rem;
                line-height: 1.267rem;
            }
            span{
                font-size: 1.125rem;
                line-height: 1.629rem;
            }
        }
    }
`

const Section7Logo = styled.div`
    position: absolute;
    top: 20%;
    right: 80%;
    opacity: 20%;
    @media (max-width:1040px){
        right: 0;
        top: 30%;
    }
    img{
        @media (max-width:1040px){
            width: 300px;
            height: 300px;
        }
    }
`

const scrollAni = keyframes`
    0%{
        transform: translateY(0);
    }
    
    100%{
        transform: translateY(100px);
    }
`

const ScorllBox = styled.div`
    position: fixed;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    p{
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.267rem;
    }
    @media (max-width:1040px){
        display: none;
    }
`

const ScrollBoxWrap = styled.div`
    position: relative;
    margin-top: 10px;
    &::before{
        content: "";
        width: 1px;
        height: 100px;
        background: #000;
        position: absolute;
        left: 50%;
        top: 30%;
    }   
    &::after{
        content: "";
        width: 2px;
        height: 20px;
        background: #fff;
        position: absolute;
        left: 50%;
        top: 30%;
        animation: ${scrollAni} 1s infinite;
    }
    @media (max-width:1040px){
        display: none;
    }
`

const MScrollBox = styled.div`
    position: fixed;
    bottom: 65px;
    left: -10px;
    z-index: 10;
    p{
        transform: rotate(-90deg);
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.267rem;
    }
    @media (min-width:1041px){
        display: none;
    }
`

const MScrollBoxWrap = styled.div`
    position: relative;
    margin-top: 40px;
    &::before{
        content: "";
        width: 1px;
        height: 60px;
        background: #000;
        position: absolute;
        left: 50%;
        top: 30%;
    }   
    &::after{
        content: "";
        width: 2px;
        height: 10px;
        background: #fff;
        position: absolute;
        left: 50%;
        top: 30%;
        animation: ${scrollAni} 1s infinite;
    }
`

const MdownBtn = styled.div`
    cursor: pointer;
    @media (min-width:1041px){
        display: none;
    }
    @media (max-width:1040px){
        z-index: 10; 
        position: fixed;
        bottom: 3%;
        left: 50%;
        transform: translateX(-50%);
        padding: 18px 57px;
        border-radius: 30px;
        background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);
        color: #fff;
    }
`

function MainPage() {

    const swiperUseRef = useRef(null);

    const updateHeight = () => {
        if (swiperUseRef.current) {
          swiperUseRef.current.style.height = `${window.innerHeight}px`;
        }
      };
    
      useEffect(() => {

        updateHeight();

        window.addEventListener('resize', updateHeight);
    
        return () => {
          window.removeEventListener('resize', updateHeight);
        };
      }, []);

    const [activeIndex, setActiveIndex] = useState(0);

    const handleSlideChange = (swiper) => {
        setActiveIndex(swiper.activeIndex);
    };

    const hadleDownload = () => {
        alert("준비 중입니다.");
    };

    return (
        <>
            <LayoutBg>
                <MainHeader activeIndex={activeIndex} />
                <Swiper
                    ref={swiperUseRef}
                    className='height'
                    direction={'vertical'}
                    slidesPerView={1}
                    mousewheel={true}
                    speed={1000}
                    pagination={{
                        clickable: true,
                        el: '.swiper-pagination', 
                    }}
                    modules={[Mousewheel, Pagination]}
                    onSlideChange={handleSlideChange}
                >
                    {/* 1 */}
                    <SwiperSlide>
                        <Section1Wrap>
                            <Section1>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: activeIndex === 0 ? 1 : 0 }}
                                    transition={{ duration: 1.5, ease: "easeInOut" }}>
                                    <Section1Title>
                                        <p>둘이 함께 하면 더 즐거운</p>
                                        <span>Doori Walk</span>
                                    </Section1Title>
                                </motion.div>
                                <Section1Box>
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: activeIndex === 0 ? 1 : 0 }}
                                        transition={{ duration: 1.5, ease: "easeInOut" }}>
                                        <Section1SubTitle>
                                            <p>걷기습관도 만들고 Kcal 소모도 하고</p>
                                            <p>랭킹별 토큰을 받을 수 있는 플랫폼 !</p>
                                        </Section1SubTitle>
                                        <Section1DownText>앱 다운로드 받으러가기</Section1DownText>
                                        <Section1LeftImgBox>
                                            <img src='./../img/down_app.png' onClick={hadleDownload} />
                                            <img src='./../img/down_google.png' onClick={hadleDownload} />
                                        </Section1LeftImgBox>
                                    </motion.div>
                                    <motion.div
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: activeIndex === 0 ? 1 : 0, y: activeIndex === 0 ? 0 : 50 }}
                                        transition={{ duration: 1.5, ease: "easeInOut", delay:0.2 }}>
                                        <img src='./../img/circle_group.png' style={{width:'100%'}} />
                                    </motion.div>
                                </Section1Box>
                            </Section1>
                        </Section1Wrap>
                    </SwiperSlide>
                    {/* 2 */}
                    <SwiperSlide>
                        <Section1Wrap>
                            <Section1>
                                <Section2Container>
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: activeIndex === 1 ? 1 : 0 }}
                                        transition={{ duration: 1.5, ease: "easeInOut" }}>
                                        <Section2Title>
                                            <span>쉽고 빠른 만보기</span>
                                        </Section2Title>
                                        <Section1SubTitle>
                                            <p>간단하게 Start 버튼만 누르면</p>
                                            <p>걸은만큼 Kcal 랭킹 순위로</p>
                                            <p>매일 토큰이 쌓여요.</p>
                                        </Section1SubTitle>
                                    </motion.div>
                                    <motion.div
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: activeIndex === 1 ? 1 : 0, y: activeIndex === 1 ? 0 : 50 }}
                                        transition={{ duration: 1.5, ease: "easeInOut" , delay:0.2}}>
                                            <img src='./../img/section2.png' />
                                    </motion.div>
                                </Section2Container>
                            </Section1>
                        </Section1Wrap>
                    </SwiperSlide>
                    {/* 3 */}
                    <SwiperSlide>
                        <Section1Wrap>
                            <Section1>
                                <motion.div 
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: activeIndex === 2 ? 1 : 0 }}
                                        transition={{ duration: 1.5, ease: "easeInOut" }}>
                                    <Section1Title>
                                        <span>Solo Rank</span>
                                    </Section1Title>
                                </motion.div>
                                <Section1Box>
                                    <Section1SubTitle >
                                        <motion.div style={{zIndex:'11'}}
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: activeIndex === 2 ? 1 : 0 }}
                                            transition={{ duration: 1.5, ease: "easeInOut" }}>
                                            <p>유저 혼자 진행되며</p>
                                            <p>리워드 및 랭킹 등 기본적인 활동을</p>
                                            <p>할 수 있어요.</p>
                                        </motion.div>
                                        </Section1SubTitle>
                                        {/* <SectionImg> */}
                                            <motion.div
                                                initial={{ opacity: 0, y: 50 }}
                                                animate={{ opacity: activeIndex === 2 ? 1 : 0, y: activeIndex === 2 ? 0 : 50 }}
                                                transition={{ duration: 1.5, ease: "easeInOut" , delay:0.2}}>
                                                    <img src='./../img/section3.png' style={{width:'100%'}}/>
                                            </motion.div>
                                        {/* </SectionImg> */}
                                </Section1Box>
                            </Section1>
                        </Section1Wrap>
                    </SwiperSlide>
                    {/* 4 */}
                    <SwiperSlide>
                        <Section1Wrap>
                            <Section1>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: activeIndex === 3 ? 1 : 0 }}
                                    transition={{ duration: 1.5, ease: "easeInOut" }}>
                                    <Section1Title>
                                        <span>Team Rank</span>
                                    </Section1Title>
                                </motion.div>
                                <Section1Box>
                                    <Section1SubTitle style={{position:'relative'}}>
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: activeIndex === 3 ? 1 : 0 }}
                                        transition={{ duration: 1.5, ease: "easeInOut" }}>
                                            <p>커뮤니티를 이용해 2~5명의</p>
                                            <p>팀을 구성 하고 팀 목표치 설정을 통해</p>
                                            <p>추가 리워드를 얻을 수 있도록</p>
                                            <p>개발 예정에 있어요.</p>
                                        </motion.div>
                                    </Section1SubTitle>
                                    <motion.div
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: activeIndex === 3 ? 1 : 0, y: activeIndex === 3 ? 0 : 50 }}
                                        transition={{ duration: 1.5, ease: "easeInOut" , delay:0.2}}>
                                        <img src='./../img/section4.png' style={{width:'100%'}} />
                                    </motion.div>
                                </Section1Box>
                            </Section1>
                        </Section1Wrap>
                    </SwiperSlide>
                    {/* 5 */}
                    <SwiperSlide>
                        <Section1Wrap>
                            <Section1>
                                <Section1Box>
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: activeIndex === 4 ? 1 : 0 }}
                                        transition={{ duration: 1.5, ease: "easeInOut" }}>
                                        <Section1Title>
                                            <span>Battle Rank</span>
                                        </Section1Title>
                                        <Section1SubTitle style={{position:'relative'}}>
                                            <p>팀 및 개인간의 배틀을 통해</p>
                                            <p>더욱 많은 리워드를 획득 할 수 있도록</p>
                                            <p>개발 예정에 있어요.</p>
                                        </Section1SubTitle>
                                    </motion.div>
                                    <motion.div
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: activeIndex === 4 ? 1 : 0, y: activeIndex === 4 ? 0 : 50 }}
                                        transition={{ duration: 1.5, ease: "easeInOut" , delay:0.2}}>
                                        <img src='./../img/section5.png' style={{width:'100%'}} />
                                    </motion.div>
                                </Section1Box>
                            </Section1>
                        </Section1Wrap>
                    </SwiperSlide>
                    {/* 6 */}
                    <SwiperSlide>
                        <Section1Wrap>
                            <Section1>
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: activeIndex === 5 ? 1 : 0 }}
                                    transition={{ duration: 1.5, ease: "easeInOut" }}>
                                    <Section1Title>
                                        <span>Sports Rank</span>
                                    </Section1Title>
                                </motion.div>
                                <Section1Box>
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: activeIndex === 5 ? 1 : 0 }}
                                        transition={{ duration: 1.5, ease: "easeInOut" }}>
                                        <Section1SubTitle style={{position:'relative'}}>
                                            <p>러닝, 싸이클, 등산 등</p>
                                            <p>각종 레저활동을 통한</p>
                                            <p>리워드를 획득할 수 있도록</p>
                                            <p>개발 예정에 있어요.</p>
                                        </Section1SubTitle>
                                    </motion.div>
                                    <motion.div
                                        initial={{ opacity: 0, y: 50 }}
                                        animate={{ opacity: activeIndex === 5 ? 1 : 0, y: activeIndex === 5 ? 0 : 50 }}
                                        transition={{ duration: 1.5, ease: "easeInOut" , delay:0.2}}>
                                        <img src='./../img/section6.png' style={{width:'100%'}} />
                                    </motion.div>
                                </Section1Box>
                            </Section1>
                        </Section1Wrap>
                    </SwiperSlide>
                    {/* 7 */}
                    <SwiperSlide>
                        <Section1 style={{position:'relative'}}>
                            <Section7Logo>
                                <img src='./../img/logo_espero_symbol.png' />
                            </Section7Logo>
                            <Section7Wrap>
                                <Section7Title>You are history makers.</Section7Title>
                                <Section7TxtBox>
                                    <div>
                                        <p>사업자등록번호</p>
                                        <span>742-87-00560</span>
                                    </div>
                                    <div>
                                        <p>TEL</p>
                                        <span>070-4705-9101</span>
                                    </div>
                                    <div>
                                        <p>MAIL</p>
                                        <span>espero.info1@gmail.com</span>
                                    </div>
                                </Section7TxtBox>
                                <Section7Footer>
                                    <p>© 2024 ESPERO. All rights reserved.</p>
                                </Section7Footer>
                            </Section7Wrap>
                        </Section1>
                    </SwiperSlide>
                </Swiper>
                {
                    activeIndex !== 6 &&
                    <ScorllBox>
                        <p>Scroll Down</p>
                        <ScrollBoxWrap />
                    </ScorllBox>

                }
                {
                    activeIndex !== 6 &&
                    <MScrollBox>
                        <p>Scroll Down</p>
                        <MScrollBoxWrap />
                    </MScrollBox>
                }
                <MdownBtn onClick={hadleDownload}>Download</MdownBtn>
            </LayoutBg>
            <div className="swiper-pagination" style={{ display: activeIndex === 6 && 'none'}}></div>
        </>
    );
}

export default MainPage;
