const API_URL = {
    LOGIN: '/api/user/login',
    TREE: '/api/user/referral_organization',
    SMS_SEND: '/api/user/smsAuthentication',
    SMS_CHECK: '/api/user/smsAuthenticationConfirm',
    JOIN: '/api/user/signUp',
    RESET: '/api/user/passwordReset',
    POPUP: '/api/page/popup',
    SEND_EMAIL: '/api/user/emailAuthentication',
    CHECK_EMAIL: '/api/user/emailAuthenticationConfirm',
    MYINFO_UPDATE : '/api/user/myInfoUpdate',
    FAVICON_UPDATE : '/api/page/setting',
    DASHBOARD : 'api/page/basic',
    GET_QR_CODE : 'api/user/qrCode',
    POST_OTP : 'api/user/validate',
    LOGIN_USER_INFO : '/api/user/loginUserInfo',
    READ_NOTIFICATION : 'api/page/readNotification',
    NFT_PURCHASE_PAGE: '/api/nft/purchasePage',
    NFT_PURCHASE_APPLY: '/api/nft/purchaseApply',
    NFT_PURCHASE: '/api/nft/purchase',
    NFT_PURCHASE_LIST: '/api/nft/purchaseList',
    BOARD_LIST : 'api/board/getPostList',
    BOARD_DETAIL : 'api/board/getPostDetail',
    WITHDRAW_PAGE : 'api/withdraw/page',
    WITHDRAW_APPLY : 'api/withdraw/apply',
    WITHDRAW_DETAIL : 'api/withdraw/detail',
    MILEAGE_LIST : 'api/user/mileageList'
};

export default API_URL;