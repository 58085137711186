import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from "../utils/apiThunk";
import REDUX_STATUS from "../constants/reduxStatus";
import API_URL from "../constants/apiUrl";
import log from 'loglevel';

export const boardListApi = createApiThunk('board/list', API_URL.BOARD_LIST, 'get');
export const boardDetailApi = createApiThunk('board/detail', API_URL.BOARD_DETAIL, 'get');

const boardSlice = createSlice({
    name: 'board',
    initialState: {
        status: 'idle',
        error: null,
        data: null
    },

    extraReducers: (builder) => {
        builder
            .addCase(boardListApi.pending, (state) => {
                log.info('REDUX STATUS PENDING'); 
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(boardListApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(boardListApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            })
            .addCase(boardDetailApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(boardDetailApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(boardDetailApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            })
    },
});

export default boardSlice.reducer;
