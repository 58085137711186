import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components';
import { useSelector } from 'react-redux';
import { mobile, custom } from '../../utils/mediaQueries';
import { AuthInput } from '../../components/input/AuthInput';
import Footer from '../../components/layout/Footer';
import { Divider, pwEyes, pwEyesAuth } from '../../components/utils/utils';
import { reset } from '../../features/auth/authSlice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loading from '../../components/loading/Loading';
import STATUSES from '../../constants/reduxStatus';

const PageLayout = styled.div`

    width: 1290px;
    padding: 0;
    min-height: 100vh;
    margin: 0 auto;
    box-sizing: border-box;

    ${custom(1290, `
        width: 100%;
        max-width: calc(1290px + 40px);
        padding: 0 20px;
        box-sizing: border-box;
    `)}
  
  display: flex; flex-direction: column;
  justify-content: space-between;
  font-size: 18px;

  ${mobile `
    padding: 0 20px;
    font-size: 14px;
  `}

  .reset_container_wrap {
    width: 100%;
  }

  .reset_title h1 {
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    line-height: 39.84px;
    color: #000;

    margin: 110px 0 40px 0;
    ${mobile`
        margin: 40px 0 30px 0;
        font-size: 20px;
        font-weight: 700;
        line-height: 23.44px;
    `}
  }

  h3.title {
    margin: 40px 0 16px 0;
    font-weight: 700;
    font-size: 18px;
    font-family: Roboto;
    line-height: 21.09px;
    text-align: left;

    ${mobile`
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16.41px;
    text-align: left;

        margin: 30px 0 15px 0;


    `}
  }

  .user_input_wrap .user_input {
    max-width: 460px;
    ${mobile`max-width: 100%;`}
  }

  /* .user_input_wrap h3 {
    font-size: 1.5rem;
    ${mobile`font-size: 1rem;`}
  } */

  .send_btn {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    padding: 12px 20px;
    background-color: #36324a;
    cursor: pointer;
    margin-left: 10px;
  }


  .submit_btn {
    display: flex;
    justify-content: center;
    margin: 50px auto;
    width: 460px; height: 56px;

    font-family: Roboto;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: -0.04em;
    text-align: center;

    ${mobile`
        width: 100%; height: 50px;
        margin: 20px auto 50px auto;
    `}

    button {
      cursor: pointer;
      border: none;
      border-radius: 10px;
      width: 460px;
      
      padding: 15px 0;
      text-align: center;
      background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);
      color: #fff;
      font-size: 16px;
      font-weight: bold;

    ${mobile`
        width: 100%;

        font-family: Roboto;
        font-size: 16px;
        font-weight: 700;
        
        letter-spacing: -0.04em;
        text-align: center;

    
    `}


    }
  }
`;

const InputLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 12px 15px;

  ${mobile`
    padding: 12px 5px;
    flex-direction: column;
    align-items: stretch;
    font-size: 14px;
  `}

  .row-title {
    display: flex;
    align-items: center;
    width: 200px;
    font-weight: bold;
    margin-right: 20px;
    color: #222;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    vertical-align: baseline;

    font-family: Roboto;
    line-height: 18.75px;
    text-align: left;


    
    ${mobile`
        margin: 0 0 8px 0;
    `}
  }
`;


function Reset() {

    const [userData, setUserData] = useState({
        userId: "",
        userPw: "",
        userPwCheck: "",
        otpNumber: ""
    });

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const status = useSelector((state) => state.auth.status)

    const [pwVisible, setPwVisible] = useState(false);
    const [pwCheckVisible, setPwCheckVisible] = useState(false);

    const [emailRef, setEmailRef] = useState(null);
    const [pwRef, setPwRef] = useState(null);
    const [PwCheckRef, setPwCheckRef] = useState(null);
    const [otpRef, setOtpRef] = useState(null);

    const handleSubmit = async () => {

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const emailCheck = emailRegex.test(userData.userId)

        const pwCondition = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/.test(userData.userPw);
        const otpCheck = /^\d{6}$/.test(userData.otpNumber)

        // console.log('domain : ', domain)

        if (userData.userId === "") {
            alert("이메일을 입력해 주세요.");
            emailRef.current.focus();
        } else if (!emailCheck) {
            alert("올바른 형식의 이메일을 입력하세요. (example@gmail.com)");
            emailRef.current.focus();
        } else if (userData.userPw === "") {
            alert('로그인 비밀번호를 입력해주세요.')
            pwRef.current.focus();
        } else if (!pwCondition) {
            alert('10~20자 사이의 영어, 숫자, 특수문자를 포함한 비밀번호를 만들어주세요.');
            pwRef.current.focus();
        } else if (userData.userPwCheck === "") {
            alert('로그인 비밀번호 확인을 해주세요.')
            PwCheckRef.current.focus();
        } else if (userData.userPw !== userData.userPwCheck) {
            alert('비밀번호가 서로 다릅니다.');
            pwRef.current.focus();
        } else if (!otpCheck) {
            alert("otp 번호 6자리 숫자를 입력해주세요.")
            otpRef.current.focus();
        }

        else {
            const response = await dispatch(reset(userData));

            // console.log("reset response : ", response)

            if (response.error) {
                alert(response.payload.message);
            } else {
                alert('비밀번호가 변경되었습니다.');
                navigate('/');
            }
        }
    }

    const GlobalStyle = createGlobalStyle`
        body {
            background-color: #FAFFF1;
        }
    `;

    // useEffect(() => {
    //     console.table(userData)
    // }, [userData])

  return (
    <>
    {status === STATUSES.LOADING && <Loading />}
    <GlobalStyle />
    <PageLayout className='reset_container'>
        <div className='reset_container_wrap'>
            <div className='reset_title'>
                <h1>비밀번호 재설정</h1>
            </div>
            <div className='user_input_wrap'>

                <h3 className='title'>본인확인 절차이며, 다른 용도로 사용하지 않습니다. </h3>

                <Divider $isThick={true} />

                <InputLayout>
                    <div className="row-title">이메일</div>
                    <AuthInput // 추천인
                        className="user_input"
                        type="text"
                        placeholder="이메일 입력"
                        onChange={(value) => {
                            setUserData({
                                ...userData, userId: value
                            })
                        }}
                        getRef={(ref) => setEmailRef(ref)}
                    >
                    </AuthInput>
                </InputLayout>

                <Divider $isThick={false} />


                <InputLayout>
                    <div className="row-title">구글 OTP 코드</div>
                    <AuthInput // otp
                        className="user_input"
                        type="text"
                        placeholder="구글 OTP 코드 입력"
                        onChange={(value) => {
                            setUserData({
                                ...userData, otpNumber: value
                            })
                        }}
                        maxLength={6}
                        getRef={(ref) => setOtpRef(ref)}
                    >
                    </AuthInput>
                </InputLayout>

                <Divider $isThick={false} />

                <InputLayout>
                    <div className="row-title">로그인 비밀번호</div>
                    <AuthInput // 비밀번호
                        className="user_input"
                        type={pwVisible ? "text" : "password"}
                        title="로그인 비밀번호"
                        placeholder="비밀번호 입력 (영문, 숫자, 특수문자 포함 10자리 이상)"
                        onChange={(value) => setUserData({
                            ...userData, userPw: value
                        })}
                        maxLength={20}
                        getRef={(ref) => setPwRef(ref)}
                    >

                        {pwEyesAuth(pwVisible, () => setPwVisible(!pwVisible),
                            {width: '25px', padding: '7.5px 10px', boxSizing: 'content-box'})}

                    </AuthInput>
                </InputLayout>

                <Divider $isThick={false} />

                <InputLayout>
                    <div className="row-title">비밀번호 확인</div>
                    <AuthInput // 비밀번호 확인
                        className="user_input"
                        type={pwCheckVisible ? "text" : "password"}
                        title="로그인 비밀번호 확인"
                        placeholder="비밀번호 한 번 더 입력"
                        onChange={(value) => setUserData({
                            ...userData, userPwCheck: value
                        })}
                        maxLength={20}
                        getRef={(ref) => setPwCheckRef(ref)}
                    >
                        {pwEyesAuth(pwCheckVisible, () => setPwCheckVisible(!pwCheckVisible),
                        {width: '25px', padding: '7.5px 10px', boxSizing: 'content-box'})}

                    </AuthInput>
                </InputLayout>

                <Divider $isThick={false} />


            </div>
            <div className='submit_btn'>
                <button className='' onClick={handleSubmit}>
                    비밀번호 변경
                </button>
            </div>
        </div>
        <Footer />
    </PageLayout>

    

    </>
  )
}

export default Reset