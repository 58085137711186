import React, { useEffect, useRef } from 'react';
import Header from '../components/layout/Header';
import styled from 'styled-components';

const LayoutBg = styled.div`
  ::-webkit-scrollbar {
      display: none;
  }
  background-color: #FAFFF1;
  font-family: "NotoSansKR";
`

const Section1Wrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 80px 20px 0px 20px;
  @media (max-width:780px){
      align-items: start;
      height: 100%;
      padding: 100px 20px 0px 20px;
  }
`

const Section1 = styled.div`
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
`

const Section1Box = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width:1040px){
      flex-wrap: wrap;
  }
`

const Section1Title = styled.div`
  font-weight: 700;
  p{
      font-size: 1.5rem;
      line-height: 2.172rem;
  }
  span{
      font-size: 3.75rem;
      line-height: 5.43rem;
  }
  @media (max-width:1040px){
      p{
          font-size: 1rem;
          line-height: 1.448rem;
      }
      span{
          font-size: 2.5rem;
          line-height: 3.62rem;
      }
  }
`

const Section1SubTitle = styled.div`
  padding-top: 30px;
  font-weight: 400;
  p{
      font-size: 2rem;
      line-height: 2.896rem;
      white-space: nowrap;
  }
  @media (max-width:1040px){
      padding-top: 10px;
      margin-bottom: 40px;
      p{
          font-size: 1.25rem;
          line-height: 1.81rem;
      }
  }
`

const Section1DownText = styled.div`
  font-size: 1rem;
  line-height: 1.448rem;
  margin-top: 62px;
  margin-bottom: 20px;
  @media (max-width:1040px){
      display: none;
  }
`

const Section1LeftImgBox = styled.div`
  display: flex;
  gap: 20px;
  img{
    cursor: pointer;
  }
  @media (max-width:1040px){
      display: none;
  }
`

const MdownBtn = styled.div`
  cursor: pointer;
  @media (min-width:1041px){
      display: none;
  }
  @media (max-width:1040px){
      position: absolute;
      bottom: 3%;
      left: 50%;
      transform: translateX(-50%);
      padding: 18px 57px;
      border-radius: 30px;
      background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);
      color: #fff;
  }
`

const FooterContainer = styled.div`
  width: 100%;
  padding: 43px 0;
  position: fixed;
  bottom: 0;
  display: flex; 
  align-items: center;
  justify-content: center;
  p{
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.026rem;
    text-align: center;
    color: #AAAAAA;
  }
  @media (max-width:780px){
    position: relative;
    padding: 32px 20px;
  }
`

function DooriWalk() {
  const mainUseRef = useRef(null);

  const updateHeight = () => {
    if (mainUseRef.current) {
      mainUseRef.current.style.height = `${window.innerHeight}px`;
    }
  };

  useEffect(() => {

    updateHeight();

    window.addEventListener('resize', updateHeight);

    return () => {
      window.removeEventListener('resize', updateHeight);
    };
  }, []);

  const hadleDownload = () => {
    alert("준비 중입니다.");
  };

  return (
    <>
      <LayoutBg>
        <Header />
        <Section1Wrap ref={mainUseRef}>
          <Section1>
            <Section1Title>
              <p>둘이 함께 하면 더 즐거운</p>
              <span>Doori Walk</span>
            </Section1Title>
            <Section1Box>
              <div>
                <Section1SubTitle>
                  <p>걷기습관도 만들고 Kcal 소모도 하고</p>
                  <p>랭킹별 토큰을 받을 수 있는 플랫폼 !</p>
                </Section1SubTitle>
                <Section1DownText>앱 다운로드 받으러가기</Section1DownText>
                <Section1LeftImgBox>
                  <img src='./../img/down_app.png' onClick={hadleDownload} />
                  <img src='./../img/down_google.png' onClick={hadleDownload} />
                </Section1LeftImgBox>
              </div>
              <div>
                <img src='./../img/circle_group.png' style={{ width: '100%' }} />
              </div>
            </Section1Box>
          </Section1>
          <MdownBtn onClick={hadleDownload}>Download</MdownBtn>
        </Section1Wrap>
        <FooterContainer>
          <p>© 2024 ESPERO. All rights reserved.</p>
        </FooterContainer>
      </LayoutBg>
    </>
  )
}

export default DooriWalk