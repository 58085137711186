
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../features/auth/authSlice';
import { useNavigate } from 'react-router-dom';
import STATUSES from '../constants/reduxStatus';
import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"

const SideBtn = styled.div`
    position: absolute; top: 0; right: 0;
    display: flex; align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
`;

const InputStyled = styled.input`
    border: ${p => p.$styled.border};
    background-color: ${p => p.$styled.backgroundColor};
    padding: 10px;
    border-radius: 5px;
    outline: none;
`;

const UserInput = (props) => {

    const {
        border='1px solid blue',
        borderFocus = '1px solid pink',
        borderError = '1px solid red',
        bg = "#fff",
        bgFocus = "#fff",
        bgError = "#fff",
        ...rest
    } = props
    
    const [currentStatus, setCurrentStatus] = useState('default') // focus, error

    const styledProps = {

            // 삼항 연산자 중첩 ㄷㄷ
            border: currentStatus === 'default' ? border : 
                currentStatus === 'focus' ? borderFocus : borderError,
            backgroundColor: currentStatus === 'default' ? bg : 
                currentStatus === 'focus' ? bgFocus : bgError,

    }

    return (
        <div style={{width: '100%', position: 'relative'}}>
            <InputStyled
                $styled={styledProps}
                onFocus={() => setCurrentStatus('focus')}
                onBlur={() => setCurrentStatus('default')}
                onInput={() => setCurrentStatus('focus')}
                {...rest}
            />

            <SideBtn>
                {props.children}
            </SideBtn>
        </div>
    )
}


const Sample = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { status, error } = useSelector((state) => state.auth);
    const [credentials, setCredentials] = useState({
        userId: '',
        userPw: '',
    });

    const loginHandler = (e) => {
        e.preventDefault();
        dispatch(login(credentials));
    };

    const credentialChangeHandler = (key, value) => {
        setCredentials({
            ...credentials,
            [key]: value,
        });
    }

    useEffect(() => {
        if (status === STATUSES.SUCCEEDED) {
            // navigate 이동
            navigate('/tree');
        }
    }, [status]);

    return (
        <>
            <div>
                <form onSubmit={loginHandler}>
                    <label>
                        Username:
                        <input
                            type="text"
                            value={credentials.userId}
                            onChange={(e) => credentialChangeHandler('userId', e.target.value)}
                        />
                    </label>
                    <br />
                    <label>
                        Password:
                        <input
                            type="password"
                            value={credentials.userPw}
                            onChange={(e) => credentialChangeHandler('userPw', e.target.value)}
                        />
                    </label>
                    <br />
                    <button type="submit">Login</button>
                </form>
                {status === STATUSES.LOADING && <p>Loading...</p>}
                {status === STATUSES.SUCCEEDED && <p>SUCCESS</p>}
                {status === STATUSES.FAILED && <p>{error}</p>}
                <div style={{backgroundColor: 'green', padding: '20px'}}>
                    <UserInput>

                    </UserInput>
                </div>

            </div>

            {/* 
                <div style="font-family: Roboto; width: calc(100% - 10px); background-color: #222222; border-radius: 20px; padding: 40px 30px; box-sizing: border-box; margin: 10px auto; font-size: 16px; max-width: 600px;">
                    <div style="width: 100%; text-align: center; margin-bottom: 30px;">
                        <img src="https://www.espero.co.kr/img/espero_row_W.png" style="width: 40%; display: block; margin: 0 auto;" alt="ESPERO Logo" />
                    </div>
                    <div style="background-color: white; padding: 30px; color: black; text-align: center; border-radius: 10px;">
                        <h1 style="padding-bottom: 20px; border-bottom: 1px solid #d2d2d2; font-size: 24px; margin: 0;">인증번호 확인</h1>
                        <div style="padding-top: 20px; line-height: 24px;">
                            <p style="margin: 0;">안녕하세요. <b>ESPERO</b>입니다.</p>
                            <p style="padding-bottom: 20px; margin: 0;"><b>??</b>님의 인증번호 확인 요청이 있습니다.</p>
                        </div>
                        <div style="background-color: #eee; padding: 20px; border-radius: 8px;">
                            <p style="margin: 0;">인증번호</p>
                            <h1 style="color: #16BFB6; font-size: 34px; margin: 10px 0 0 0;">123456</h1>
                        </div>
                    </div>
                    <div style="color: #aaa; text-align: center; margin-top: 20px; line-height: 22px;">
                        <p style="margin: 0;">본 메일은 서비스 이용을 위한 필수사항으로</p>
                        <p style="margin: 0;"><b style="color: white;">인증번호 6자리를 진행중인 화면에 입력</b>하여 주시기 바랍니다.</p>
                        <br style="margin: 0;" />
                        <p style="margin: 0;">※ 인증번호는 메일이 발송된 시점부터 00분간 유효합니다.</p>
                        <b style="color: #A6FF1B; margin: 0;">(인증만료시간 : 0000년 00월 00일 00:00)</b>
                    </div>
                </div>                       
            */}

        </>

    )
}

export default Sample