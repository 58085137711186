export const ROUTER_PATHS = Object.freeze({
    HOME: '/home',
    LOGIN: '/login',
    ERROR: '/error',
    NOT_FOUND: '/not-found',
    TREE: '/tree',
    JOIN: '/join',
    RESET: '/reset',
    DASHBOARD : '/dashBoard',
    MAINPAGE : '/',
    MYINFO : '/myInfo',
    DOORIWALK : '/dooriwalk',
    VILAGE: '/vilage',
    LINE_TREE: '/line-tree',
    BOARD: '/board',
    BOARD_DETAIL: '/boardDetail',
    ORDER_REQUEST: '/order-request',
    ORDER_HISTORY: '/order-history',
    REQUEST_WITHDRAWAL : '/request-withdrawal', 
    WITHDRAWAL_DETAILS : '/withdrawal-details',
    MILEAGE: '/mileage'
});
