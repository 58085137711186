import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { mobile } from '../../utils/mediaQueries'


export const InputStyle = styled.div`

    position: relative;
    box-sizing: border-box;
    flex: 1;
    padding: 9px 20px; // border box 왜.. 안먹혀..
    ${mobile`padding: 4px 10px;`}
    border-radius: 5px;
    border: ${p => {
        if (p.$isFocus) {
            return p.$borderFocus
        } else {
            return p.$border
        }
    }};
    background-color: ${p => {
        if (p.$isFocus) {
            return p.$bgFocus
        } else {
            return 'rgba(255,255,255,0.85)'
        }
    }};
    
    font-family: Roboto;
    font-weight: 400;



    

    display: flex; justify-content: center;
    align-items: center;

    ::placeholder{
        ${mobile`
            font-family: Roboto;
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: -0.02em;
            text-align: left;
        `}
        color: #6F6F6F;
    }

    input {
        border: none;
        outline: none;
        width: 100%;
        font-size: 16px;
        line-height: 25px;
        background: transparent;
        color: #222;
        

        ${mobile`
            font-size: 14px;
            font-weight: 400;

        `}


    }
`;

const SideBtn = styled.div`
    position: absolute; top: 0; right: 0; 
    cursor: pointer;
    display: flex; align-items: center;
    justify-content: center;
    height: 100%;
    /* padding: 0 20px; */

`;

export const AuthInput = (props) => {

    const {   
        type="text",
        onChange= () => {},
        onClick= () => {},
        border = '1px solid #C4FF69',
        borderFocus = '1px solid #16BFB6',
        bgFocus = "#fff",
        children,
        value, style,
        className,
        getRef,
        ...rest

    } = props
    
        const [inputData, setInputData] = useState("");
        const [isFocus, setIsFocus] = useState(false);

        useEffect(() => {
            if (isFocus) inputRef.current.focus();
        }, [isFocus])

        useEffect(() => {
            onChange(inputData);
        }, [inputData])
        
        useEffect(() => {
            if (value) {
                setInputData(value);
            }
        }, [value])

        useEffect(() => {
            if (value !== undefined && value !== inputData) {
                setInputData(value);
            }
        }, [value]);

        useEffect(() => {
            if (inputRef !== null) {
                getRef && getRef(inputRef);
            }
        })

        const inputRef = useRef(null);
    
    return (
            <InputStyle
                className={className}
                tabIndex={0}
                onFocus={() => setIsFocus(true)}
                onClick={onClick}
                style={style}
                $border={border}
                $borderFocus={borderFocus}
                $bgFocus={bgFocus}
                $isFocus={isFocus}
            >
                {/* <span>icon</span> */}
                <input
                    type={type}
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                    onBlur={() => {
                        setIsFocus(false);
                    }}
                    onFocus={() => setIsFocus(true)}
                    ref={inputRef}
                    {...rest}
                />

                <SideBtn>
                    {children}
                </SideBtn>
                
            </InputStyle>

    )
}
