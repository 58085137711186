import styled, { css } from 'styled-components';

export const mobile = (...args) => css`
    @media (max-width: 780px) {
        ${css(...args)};   }
`;

export const custom = (size, styles) => css`
  @media (max-width: ${size}px) {
    ${styles}
  }
`;