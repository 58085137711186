import React, {useEffect, useRef} from 'react';
import {tree} from "../features/auth/authSlice";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import Header from '../components/layout/Header';
import styled from 'styled-components';
import Footer from "../components/layout/Footer";
import STATUSES from "../constants/reduxStatus";
import Loading from "../components/loading/Loading";


const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    height: 100vh;
    font-size: 24px;

    //padding-top: 100px;
    background-color: #FAFFF1;

`;

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    font-size: 24px;
    height: 100%;
    //padding: 210px 20px 0 20px;
    width: 100%;
    flex: 1;
    overflow-y: hidden;
    margin-top: 100px;
    padding: 110px 20px 100px 20px;
    
    @media (max-width: 768px) {
        margin-top: 60px;
        padding: 40px 20px 100px 20px;
    
    }
`;

const Title = styled.div`
    font-size: 34px;
    font-weight: 700;
    //line-height: 39.84;
    width: 100%;
    text-align: center;
    color: #000000;
    margin-bottom: 40px;

    @media (max-width: 768px) {
        margin-bottom: 30px;
        color: #000;
        text-align: center;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
`;

const TreeTitle = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    //line-height: 39.84;
    color: #000000;
    width: 100%;
    max-width: 1290px;
    //border: 1px solid #000;
    height: 50px;
    min-height: 50px;
    background-color: #FFFFFF;
    //margin-bottom: 12px;
    margin-bottom: 3px;
    box-shadow: 0 4px 13px 0 #0000001A;
    color: #222;
`;

const TreeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    height: 100%;
    font-size: 16px;
    font-weight: 500;
    //border: 1px solid #000;
    max-width: 1290px;
    width: 100%;
`;

const TreeItemContainer = styled.div`
    /* display: flex; flex-direction: column; */
    width: 100%;
    overflow-y: scroll;
    padding-left: 25px;
    padding-bottom: 3px;
    color: #222;

    .item-wrap{
        height: 56px;

        &:last-child{
            // 마지막 요소 그림자 영역 확보하기
            height: 65px; // 부모가 flex가 되면 스크롤할때 65보다 더 줄여져버림
            align-items: flex-start !important;
            /* margin-bottom: auto; */
        }
    }

    &::-webkit-scrollbar {
        width: 12px;
        height: 12px;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 5px;
        margin-bottom: 12px; // 스크롤바 강제로 줄임
    }

    &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
    }

`
const Tree = styled.div`
    display: flex;
    justify-content: start;
    align-items: center;
    //height: 100%;
    //  border: 1px solid #000;
    max-width: 1290px;
    width: 100%;
    height: 50px;
    min-height: 50px;
    background-color: #FFFFFF;
    margin: 3px 0;
    box-sizing: border-box;
    box-shadow: 0 4px 13px 0 #0000001A;
`;

const Line = styled.div`
    width: 2px;
    height: 100%;
    background-color: #16BFB6;
`;

const Line2 = styled.div`
    width: 2px;
    height: 100%;
    background-color: #A6FF1B;
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-left: 13px;
    img{
        width: 24px;
        height: 24px;
    }
`;

const Icon = styled.img`
    width: 10px;
    height: 4.5px;
`;

const SubLineContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* justify-content: start; */
    align-items: start;
    //width: 32px;
    height: 100%;
    //border: 1px solid #000;
`

const SubLine = styled.div`
    width: 1px;
    height: 50%;
    border-left: 1px dashed #D9D9D9;
    margin-right: 0.8px;
`

const SubLine2Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;
    //border: 1px solid #000;

    
`

const SubLine2 = styled.div`
    width: 31px;
    height: 1px;
    border-top: 1px dashed #D9D9D9;

    @media (max-width: 768px) {
        width: 15px;
    }
`

const LineTree = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const treeItemContainerRef = useRef(null);
    const [isOpen, setIsOpen] = React.useState(true);
    // const [currentPage, setCurrentPage] = React.useState(1);
    const {status, error, data} = useSelector((state) => state.auth);

    // const data = { // 더미데이터
    //     "depth": 1,
    //     "id": 1215,
    //     "joinDate": "2024-06-27",
    //     "parentId": 1213,
    //     "parentUserId": "admin@espero.co.kr",
    //     "position": 1,
    //     "rootId": "admin@espero.co.kr",
    //     "userId": "swlkh74@naver.com",
    //     "userRankEnName": "member",
    //     "children" : [
    //         {
    //             "depth": 1,
    //             "id": 1215,
    //             "joinDate": "2024-06-27",
    //             "parentId": 1213,
    //             "parentUserId": "admin@espero.co.kr",
    //             "position": 1,
    //             "rootId": "admin@espero.co.kr",
    //             "userId": "swlkh74@naver.com",
    //             "userRankEnName": "member"
    //         },
    //         {
    //             "depth": 1,
    //             "id": 1215,
    //             "joinDate": "2024-06-27",
    //             "parentId": 1213,
    //             "parentUserId": "admin@espero.co.kr",
    //             "position": 1,
    //             "rootId": "admin@espero.co.kr",
    //             "userId": "swlkh74@naver.com",
    //             "userRankEnName": "member"
    //         },
    //         {
    //             "depth": 1,
    //             "id": 1215,
    //             "joinDate": "2024-06-27",
    //             "parentId": 1213,
    //             "parentUserId": "admin@espero.co.kr",
    //             "position": 1,
    //             "rootId": "admin@espero.co.kr",
    //             "userId": "swlkh74@naver.com",
    //             "userRankEnName": "member"
    //         },
    //         {
    //             "depth": 1,
    //             "id": 1215,
    //             "joinDate": "2024-06-27",
    //             "parentId": 1213,
    //             "parentUserId": "admin@espero.co.kr",
    //             "position": 1,
    //             "rootId": "admin@espero.co.kr",
    //             "userId": "swlkh74@naver.com",
    //             "userRankEnName": "member"
    //         },
    //         {
    //             "depth": 1,
    //             "id": 1215,
    //             "joinDate": "2024-06-27",
    //             "parentId": 1213,
    //             "parentUserId": "admin@espero.co.kr",
    //             "position": 1,
    //             "rootId": "admin@espero.co.kr",
    //             "userId": "swlkh74@naver.com",
    //             "userRankEnName": "member"
    //         },
    //     ]
    //   }

    useEffect(() => {
        dispatch(tree());
    }, [dispatch]);



    // useEffect(() => {
    //     const handleScroll = () => {
    //         console.log('scroll')
    //         if (treeItemContainerRef.current) {
    //             const {scrollTop, scrollHeight, clientHeight} = treeItemContainerRef.current;
    //             if (scrollTop + clientHeight >= scrollHeight) {
    //                 // dispatch(tree({page: currentPage + 1}));
    //                 setCurrentPage(prevPage => prevPage + 1);
    //             }
    //         }
    //     };
    //
    //     const currentRef = treeItemContainerRef.current;
    //     if (currentRef) {
    //         currentRef.addEventListener('scroll', handleScroll);
    //     }
    //     return () => {
    //         if (currentRef) {
    //             currentRef.removeEventListener('scroll', handleScroll);
    //         }
    //     };
    // }, []);

    if(status === STATUSES.FAILED) {
        navigate("/error")
    }

    return (
        <>

            <Container>
                <Header/>
                <MainContainer>
                    <Title>라인뷰</Title>
                    {status === STATUSES.LOADING ? <Loading/> :
                    <TreeContainer>
                        <TreeTitle onClick={() => setIsOpen(!isOpen)}>
                            <Line/>
                            <IconContainer>
                                <Icon src={`/img/${isOpen ? 'down.png' : 'up.png'}`}/>
                            </IconContainer>
                            {data?.userId + " / "}
                            {data?.userNickName === "null" ? " - " : data?.userNickName}
                            {" / "}
                            {data?.position === 0 && "normal"}
                            {data?.position === 1 && "member"}
                            {data?.position === 2 && "vip"}
                        </TreeTitle>
                        {isOpen &&
                            <TreeItemContainer ref={treeItemContainerRef} className='tree-item-container'>

                                {status === STATUSES.SUCCEEDED && (
                                    data?.children?.length === 0 || !data || !data?.children? (
                                        <div className='item-wrap' style={{display: "flex", flexDirection: 'row', width: '100%'}}>
                                            <SubLineContainer>
                                                <SubLine/>

                                            </SubLineContainer>
                                            <SubLine2Container>
                                                <SubLine2/>
                                            </SubLine2Container>
                                            <Tree >
                                                <Line2/>
                                                <IconContainer style={{marginRight: 0}}>
                                                    {/*<Icon src={"/img/up.png"}/>*/}
                                                </IconContainer>
                                                데이터가 없습니다.
                                            </Tree>
                                        </div>
                                    ) : (
                                        data?.children?.map((item, index) => {
                                            return (
                                                <div className='item-wrap' style={{display: "flex", flexDirection: 'row', width: '100%', alignItems: 'center'}}
                                                     key={item.id}>
                                                    <SubLineContainer className='subline-container'>
                                                        <SubLine className='subline'/>
                                                        {index !== data?.children?.length - 1 && <SubLine className='subline'/>}
                                                    </SubLineContainer>
                                                    <SubLine2Container className='subline-2-container'>
                                                        <SubLine2/>
                                                    </SubLine2Container>
                                                    <Tree key={index}>
                                                        <Line2/>
                                                        <IconContainer style={{marginRight: 0}}>
                                                            {/*<Icon src={"/img/up.png"}/>*/}
                                                        </IconContainer>

                                                        <span style={{paddingRight: 20}}>
                                                            {item.userId + " / "}
                                                            {item?.userNickName === "null" ? " - " : item?.userNickName}
                                                            {" / "}
                                                            {item.position === 0 && "normal"}
                                                            {item.position === 1 && "member"}
                                                            {item.position === 2 && "vip"}

                                                        </span>
                                                    </Tree>
                                                </div>
                                            )

                                        })


                                    ))}


                            </TreeItemContainer>
                        }

                    </TreeContainer>
                    }
                </MainContainer>
                <Footer/>
            </Container>

        </>

    );
}

export default LineTree;