import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from "../utils/apiThunk";
import REDUX_STATUS from "../constants/reduxStatus";
import API_URL from "../constants/apiUrl";
import log from 'loglevel';

export const myInfoApi = createApiThunk('myinfo/modify', API_URL.MYINFO_UPDATE, 'post');
export const getQrCodeApi = createApiThunk('myinfo/getQrCode', API_URL.GET_QR_CODE, 'get');
export const postOtpApi = createApiThunk('myinfo/postOtp', API_URL.POST_OTP, 'post');
export const userInfoApi = createApiThunk('myinfo/userInfo', API_URL.LOGIN_USER_INFO, 'post');

const myInfoSlice = createSlice({
    name: 'myinfo',
    initialState: {
        status: 'idle',
        error: null,
        data: null,
        otpData: {
            otpKey: '',
            otpUrl: ''
        },
    },
    reducers: {
        resetState: (state) => {
            return state = {
                status: 'idle',
                error: null,
                data: null,
                otpData: {
                    otpKey: '',
                    otpUrl: ''
                },
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(myInfoApi.pending, (state) => {
                log.info('REDUX STATUS PENDING'); 
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(myInfoApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(myInfoApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
                alert(action.payload.message);
            })
            .addCase(getQrCodeApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(getQrCodeApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = {
                    ...state.data,
                    otpKey: action.payload.otpKey,
                    otpUrl: action.payload.otpUrl
                };
                if (action.payload.otpKey && action.payload.otpUrl) {
                    state.otpData.otpKey = action.payload.otpKey;
                    state.otpData.otpUrl = action.payload.otpUrl;
                }
            })
            .addCase(getQrCodeApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            })
            .addCase(postOtpApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(postOtpApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(postOtpApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
                alert(action.payload.message);
            })
            .addCase(userInfoApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(userInfoApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(userInfoApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            })
    },
});

export const { resetState } = myInfoSlice.actions;

export default myInfoSlice.reducer;
