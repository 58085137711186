import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from "../../utils/apiThunk";
import REDUX_STATUS from "../../constants/reduxStatus";
import API_URL from "../../constants/apiUrl";
import log from 'loglevel';

export const popupApi = createApiThunk('page/popup', API_URL.POPUP, 'get');
export const setFaviconApi = createApiThunk('page/favicon', API_URL.FAVICON_UPDATE, 'get'); // (질문) addCase 꼭 해야되나

const pageSlice = createSlice({
    name: 'page',
    initialState: {
        data: {
            isFirstLoaded : true,
            currentLanguage: 'kr',
            popup: {},
            ignorePopup: false
        },
        status: 'idle',
        error: null,
    },
    reducers: {
        setFirstLoaded(state, action){

        },
        checkIgnoreNotice(state){
            const isIgnore = localStorage.getItem('ignoreNoticeToday');

            if (isIgnore) { // ignoreNoticeToday가 있으면

                // console.log('isIgnore : ', isIgnore)

                const today = new Date().getTime();
                const deadLine = parseInt(isIgnore);
                
                // console.log('today: ', today)
                // console.log('deadLine : ', deadLine)
                // console.log('today > deadLine: ', today > deadLine)

                if (today >= deadLine) {
                    localStorage.removeItem('ignoreNoticeToday');
                    state.data.ignorePopup = false
                } else if (today < deadLine) {
                    state.data.ignorePopup = true
                }

            } else { // ignoreNoticeToday 없으면
                state.data.ignorePopup = false
            }

        },
        setIgnoreNotice(state, action){ // action에서 현재 팝업의 id값 받아와야함

            const tomorrow = new Date().getTime() + (24 * 60 * 60 * 1000); // 24 * 60 * 60 * 1000
            localStorage.setItem('ignoreNoticeToday', JSON.stringify([tomorrow, action.payload])); // 팝업 id 값

        },
        setLanguage(state, action) {
            state.data.currentLanguage = action.payload;
        }
    
    },
    extraReducers: (builder) => {
        builder
            .addCase(popupApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(popupApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;

                // 팝업 값 받아오기

                const currentPopupId = JSON.parse(
                    localStorage.getItem('ignoreNoticeToday')
                )

                // console.log('기존 팝업 : ', currentPopupId && currentPopupId[1])
                // console.log('새 팝업 : ', action.payload.id)
                
                if (currentPopupId && currentPopupId[1] === action.payload.id){
                    // console.log('기존 팝업 유지')
                } else {

                    // console.log('새 팝업 교체')

                    // 새 팝업 등장
                    state.data.popup = action.payload // 업뎃

                    // 새 팝업이 떴으니까 하루동안 보지않기 초기화
                    localStorage.removeItem("ignoreNoticeToday");
                }

            })
            .addCase(popupApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            })
    },
});

export const { 
    // setLoading,
    setLanguage,
    setFirstLoaded,
    checkIgnoreNotice,
    setIgnoreNotice
    
    } = pageSlice.actions;

export default pageSlice.reducer;
