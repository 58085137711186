import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from "../../utils/apiThunk";
import REDUX_STATUS from "../../constants/reduxStatus";
import API_URL from "../../constants/apiUrl";
import log from 'loglevel';

export const joinApi = createApiThunk('auth/join', API_URL.JOIN, 'post');
export const sendEmailApi = createApiThunk('auth/sendEmail', API_URL.SEND_EMAIL, 'get');
export const checkEmailApi = createApiThunk('auth/checkEmail', API_URL.CHECK_EMAIL, 'post');

const joinSlice = createSlice({
    name: 'auth',
    initialState: {
        status: 'idle',
        error: null,
    },
    reducers: {
        // setError(state, action) {
        //     state.error = action.payload;
        // },
    },
    extraReducers: (builder) => {
        builder
            .addCase(joinApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(joinApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
            })
            .addCase(joinApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            })
            .addCase(sendEmailApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(sendEmailApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
            })
            .addCase(sendEmailApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            })
            .addCase(checkEmailApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(checkEmailApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
            })
            .addCase(checkEmailApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            })
    },
});

// export const { setError } = smsSlice.actions;

export default joinSlice.reducer;
