// src/features/user/userSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from "../../utils/apiThunk";
import REDUX_STATUS from "../../constants/reduxStatus";
import API_URL from "../../constants/apiUrl";
import log from 'loglevel';

export const friend = createApiThunk('auth/user/friend', "api/user/friend", 'get');

const authSlice = createSlice({
    name: 'friend',
    initialState: {
        status: 'idle',
        error: null,
        data: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(friend.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(friend.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(friend.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            })
    },
});


export default authSlice.reducer;
