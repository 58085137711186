import React, { useEffect, useState } from 'react';
import Header from '../components/layout/Header';
// import Pagination from '../components/pagination/Pagination';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { withdrawDetailApi } from '../features/withdrawSlice';
import { useDeviceDetect } from '../components/utils/utils';
import Pagination from '../components/pagination/Pagination';

import Loading from '../components/loading/Loading';
import STATUSES from '../constants/reduxStatus';
import { addCommasToNumber } from '../utils/utils';

const LayoutBg = styled.div`
  min-height: 100vh;
  width: 100%;  
  background-color: #FAFFF1;
  padding: 100px 20px 0px;
  @media (max-width:780px){
    padding: 60px 20px 0px;
  }
`

const MainLayout = styled.div`
  max-width: 1290px;
  margin: 0 auto;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 2.125rem;
  line-height: 2.49rem;
  padding: 110px 0px 40px 0px;
  text-align: center;
  @media (max-width:880px){
    padding: 40px 0px 30px 0px;
    font-size: 1.25rem;
    line-height: 1.465rem;
  }
`

const ContentTitle = styled.div`
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.318rem;
  padding-bottom: 16px;
  border-bottom: 2px solid #666666;
  @media (max-width:880px){
    padding-bottom: 10px;
  }
`

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
  thead tr{
    padding: 15px;
  }
  thead tr th{
    height: 51px;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.318rem;
    color: #000;
  }
  tbody tr td{
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
    color: #222222;
    height: 51px;
  }
  tr{
    width: 100%;
    border-bottom: 1px solid #D9D9D9;
  }
  th,td{
    text-align: center;
  }
  @media (max-width:880px){
    display: none;
  }
`

const TableNo = styled.div`
  min-width: 65px;
`

const TableState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const TableMethod = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`

const MethodCircle = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px #0000001A;
  border-radius: 50%;
  img{
    width: 24px;
    height: 24px;
  }
`

const MethodArrow = styled.div`
  width: 20px;
  height: 20px;
  img{
    width: 100%;
  }
`

const TableWalletAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`

const TableCopyBox = styled.div`
  padding: 5px;
  background-color: #16BFB6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  img{
    width: 14px;
    height: 14px;
  }
`

const TableTrxId = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
`

const StateApproved = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.172rem;
  background-color: #16BFB6;
  border-radius: 15px;
  padding: 5px 12px;
  color: #fff;
  min-width: 87px;
  height: 29px;
`

const StatePending = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.172rem;
  background-color: #BBBBBB;
  border-radius: 15px;
  padding: 5px 12px;
  color: #fff;
  min-width: 78px;
  height: 29px;
`

const StateFailed = styled.div`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.172rem;
  background-color: #FF0E0E;
  border-radius: 15px;
  padding: 5px 12px;
  color: #fff;
  min-width: 70px;
  height: 29px;
`

// const PaginationContainer = styled.div`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   padding: 30px 0px 140px 0px;
//   @media (max-width:880px){
//     display: none;
//   }
// `

// const PaginationWrap = styled.div`
//   max-width: 305px;
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   gap: 10px;
// `

// const PaginationNumberWrap = styled.div`
//   max-width: 245px;
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   gap: 5px;
// `

// const Pagination = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   min-width: 20px;
//   height: 20px;
//   padding: 2px;
//   border-radius: 4px;
//   background-color: #fff;
//   text-align: center;
//   color: #AAAAAA;
//   font-size: 0.875rem;
//   line-height: 1.026rem;
//   cursor: pointer;
//   &.arrow{
//     box-shadow: 0px 0px 4px 0px #0000001A;
//   }
//   &.active{
//     background-color: #16BFB6;
//     color: #fff;
//   }
//   img{
//     width: 5px;
//     height: 10px;
//   }
// `

const MtableWrap = styled.div`
  padding: 15px 5px 0px 5px;
  @media (min-width:881px){
    display: none;
  }
`

const MtableTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const MtableBottom = styled.div`
  padding: 12px 0px;
  border-bottom: 1px solid #D9D9D9;
`

const MtableDate = styled.div`
  font-size: 1rem;
  line-height: 1.172rem;
  font-weight: 400;
  color: #222222;
`

const MtableStateApproved = styled(StateApproved)`
  padding: 3px 12px;
  height: 25px;
`

const MtableStatePending = styled(StatePending)`
  padding: 3px 12px;
  height: 25px;
`

const MtableStateFailed = styled(StateFailed)`
  padding: 3px 12px;
  height: 25px;
`

const MtableCnt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #222222;
  padding-bottom: 10px;
  p{
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.172rem;
  }
  span{
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.026rem;
  }
`

const MtableMethod = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #222222;
  padding-bottom: 10px;
  p{
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.172rem;
  }
`

const MtableMethodWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
`

const MtableMethodCircle = styled(MethodCircle)`
  width: 24px;
  height: 24px;
  img{
    width: 16.94px;
    height: 16.94px;
  }
`

const MtableMethodArrow = styled.div`
  width: 16px;
  height: 16px;
  img{
    width: 100%;
  }
`

const MtableWalletAddress = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #222222;
  padding-bottom: 10px;
  font-size: 1rem;
  line-height: 1.172rem;
  font-weight: 400;
  p{
    font-weight: 700;
  }
`

const MtableWalletTxt = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  span{
    cursor: pointer;
  }
`

const MtableCopyBox = styled(TableCopyBox)`
  width: 20px;
  height: 20px;
  padding: 0;
  img{
    width: 11.67px;
    height: 11.67px;
  }
`

const MtablTrxId = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #222222;
  font-size: 1rem;
  line-height: 1.172rem;
  font-weight: 400;
  p{
    font-weight: 700;
  }
`

const MviewMoreBtnWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px 50px 0px;
  @media (min-width:881px){
    display: none;
  }
`

const MviewMoreBtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  height: 43px;
  padding: 12px 22px;
  background-color: #fff;
  border-radius: 50px;
  box-shadow: 0px 0px 10px 0px #0000001A;
  cursor: pointer;
  p{
    font-size: 1rem;
    line-height: 1.172rem;
    font-weight: 700;
    color: #16BFB6;
  }
  img{
    width: 24px;
    height: 24px;
  }
`

const TableNoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-bottom: 1px solid #D9D9D9;
  color: #666666;
  padding: 150px 0px;
  @media (max-width:880px){
    padding: 180px 0px;
  }
`

const FooterContainer = styled.div`
  width: 100%;
  padding: 43px 0;
  position: relative;
  display: flex; 
  align-items: center;
  justify-content: center;
  p{
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.026rem;
    text-align: center;
    color: #AAAAAA;
  }
  @media (max-width: 880px){
    position: ${(props) => props.$nodata ? 'fixed' : 'relative'};
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  @media (max-width:780px){
    padding: 32px 20px;
  }
`

function WithdrawalDetails() {

  const dispatch = useDispatch();
  const { status, error, data } = useSelector((state) => state.withdraw);
  // 페이지 번호
  const [pageNum, setPageNum] = useState(1);

  // 한 페이지에 보여줄 게시글 수
  const pageSize = 10;
  // 모바일 출금 내역 배열
  const [detailList, setDetailList] = useState([]);

  // 웹 1개의 데이터만 있어도 9개 빈칸 , 10칸 채우는 배열 
  const filledArray = Array.from({ length: 10 }, (_, i) => data?.content?.[i] || {});

  // 지갑주소 , trxId 중간 ... 생략
  const shortenTxt = (str) => {
    if(str.length <= 10){
      return str;
    }
    const start = str.slice(0,5); // 맨 앞 5글자
    const end = str.slice(-5); // 맨 뒤 5글자
    return `${start}...${end}`;
  };

  // 복사 클릭
  const handleCopy = (item) => {

    const textCopy = document.createElement('textarea');
    const pageSize = 10;

    textCopy.innerText = item;
    document.body.appendChild(textCopy);

    textCopy.select();
    document.execCommand('copy');

    textCopy.remove();

    alert('클립보드에 복사되었습니다.');

  };

  const fetchData = async (page) => {
    try {
      setPageNum(page);
      const result = await dispatch(withdrawDetailApi({ page , pageSize : pageSize }));
      const content = result.payload.content;

      if (page === 1) {
        setDetailList(content);
      } else {
        setDetailList(prevList => [...prevList, ...content]);
      }
    } catch (error) {
      console.log("출금내역 불러오는 중 오류 발생", error);
    }
  };

  useEffect(() => { // 첫 로딩 fetch
    fetchData(1);
  }, [dispatch]);

  useEffect(() => { // pageNum 바뀔때 fetch
    fetchData(pageNum);
  }, [pageNum]);

  const isMobile = useDeviceDetect();

  useEffect(() => { // 기기 전환 될때마다 1페이지로 초기화
    setPageNum(1);
  }, [isMobile]);

  return (
    <>

      <Header />
      <LayoutBg>
        <MainLayout>
          <Title>Withdrawal details</Title>
          <ContentTitle>내역</ContentTitle>
          <TableContainer>
            {
              data?.content?.length === 0 ? 
              <TableNoData>출금 내역이 없습니다.</TableNoData>
              :
              <Table>
                <thead>
                  <tr>
                    <th>NO</th>
                    <th>수량</th>
                    <th>상태</th>
                    <th>지급방법</th>
                    <th>출금지갑주소</th>
                    <th>Trx id</th>
                    <th>Date</th>
                  </tr>
                </thead>
                <tbody>
                  {
                      filledArray.map((e, i) => {
                        const detailNumber = data?.totalElements - (pageNum - 1) * pageSize - i;
                        return (
                          <tr key={i}>
                            <td style={{minWidth:'65px'}}>{e.amount ? detailNumber : ''}</td>
                            <td style={{minWidth:'145px'}}>{e.amount ? addCommasToNumber(e.amount.toFixed(4)) : ''}</td>
                            {
                              e.status &&
                              <td style={{minWidth:'200px'}}>
                                <TableState>
                                {
                                  e.status === "1" ?
                                    <StatePending>pending</StatePending>
                                    : e.status == "2" ?
                                    <StateApproved>approved</StateApproved>
                                    : 
                                    <StateFailed>Failed</StateFailed>
                                }
                                </TableState>
                              </td>
                            }
                            <td style={{minWidth:'160px'}}>
                            <TableMethod>
                              {
                                e.method &&
                                <>
                                  <MethodCircle>
                                    <img src='./../img/common_esptoken_ico_img.png' />
                                  </MethodCircle>
                                  <MethodArrow>
                                    <img src='./../img/withdraw_arrow.png' />
                                  </MethodArrow>
                                  <MethodCircle>
                                    <img src={`${e.method === "GMMT" ? './../img/common_gmmt_ico_img.png' : './../img/common_esptoken_ico_img.png'}`} />
                                  </MethodCircle>
                                </>
                              }
                            </TableMethod>
                            </td>
                            <td style={{minWidth:'220px'}}>
                            <TableWalletAddress>
                              <p>{e.walletAddress ? shortenTxt(e.walletAddress) : ''}</p>
                              {
                                e.walletAddress &&
                                <TableCopyBox style={{marginRight:'15px'}} onClick={() => handleCopy(e.walletAddress)}>
                                  <img src='./../img/common_copy_ico.png' />
                                </TableCopyBox>
                              }
                            </TableWalletAddress>
                            </td>
                            <td style={{minWidth:'220px'}}>
                            <TableTrxId>
                              <p>{e.trxId ? shortenTxt(e.trxId) : ''}</p>
                              {
                                e.trxId &&
                                <TableCopyBox onClick={() => handleCopy(e.trxId)}>
                                  <img src='./../img/common_copy_ico.png' />
                                </TableCopyBox>
                              }
                            </TableTrxId>
                            </td>
                            <td style={{minWidth:'130px'}}>{e.status == 1 ? (e.createdAt || '') : (e.endDateAt || '')}</td>
                          </tr>
                        )
                      })
                    }
                </tbody>
              </Table>
            }
            {/* 모바일 출금 내역 */}
            {
              data?.content?.length > 0 &&
              detailList.map((e, i) => {
                return (
                  <MtableWrap key={i}>
                    <MtableTop>
                      <MtableDate>{e.status == 1 ? (e.createdAt || '') : (e.endDateAt || '')}</MtableDate>
                      {
                        e.status === "1" ?
                          <MtableStatePending>pending</MtableStatePending>
                          : e.status == "2" ?
                          <MtableStateApproved>approved</MtableStateApproved>
                          :
                          <MtableStateFailed>Failed</MtableStateFailed>
                      }
                    </MtableTop>
                    <MtableBottom>
                      <MtableCnt>
                        <p>수량</p>
                        <span>{e.amount ? addCommasToNumber(e.amount.toFixed(4)) : ''}</span>
                      </MtableCnt>
                      <MtableMethod>
                        <p>지급방법</p>
                        <MtableMethodWrap>
                          <MtableMethodCircle>
                            <img src='./../img/common_esptoken_ico_img.png' />
                          </MtableMethodCircle>
                          <MtableMethodArrow>
                            <img src='./../img/withdraw_arrow.png' />
                          </MtableMethodArrow>
                          <MtableMethodCircle>
                            <img src={`${e.method === "GMMT" ? './../img/common_gmmt_ico_img.png' : './../img/common_esptoken_ico_img.png'}`} />
                          </MtableMethodCircle>
                        </MtableMethodWrap>
                      </MtableMethod>
                      <MtableWalletAddress>
                        <p>출금지갑주소</p>
                        <MtableWalletTxt>
                          <span onClick={() => handleCopy(e.walletAddress)}>
                            {e.walletAddress ? shortenTxt(e.walletAddress) : ''}
                          </span>
                          {
                            e.walletAddress &&
                            <MtableCopyBox onClick={() => handleCopy(e.walletAddress)}>
                              <img src='./../img/common_copy_ico.png' />
                            </MtableCopyBox>
                          }
                        </MtableWalletTxt>
                      </MtableWalletAddress>
                      <MtablTrxId>
                        <p>Trx id</p>
                        <MtableWalletTxt>
                          <span onClick={() => handleCopy(e.trxId)}>
                            {e.trxId ? shortenTxt(e.trxId) : ''}
                          </span>
                          {
                            e.trxId &&
                            <MtableCopyBox onClick={() => handleCopy(e.trxId)} >
                              <img src='./../img/common_copy_ico.png' />
                            </MtableCopyBox>
                          }
                        </MtableWalletTxt>
                      </MtablTrxId>
                    </MtableBottom>
                  </MtableWrap>
                )
              })
            }
          </TableContainer>
          <Pagination data={data?.content && data} onChange={(num) => setPageNum(num)} putPageNum={pageNum} />
        </MainLayout>
        <FooterContainer $nodata={data?.content?.length === 0}>
          <p>© 2024 ESPERO. All rights reserved.</p>
        </FooterContainer>
      </LayoutBg>
    </>
  )
}

export default WithdrawalDetails