import { createSlice } from '@reduxjs/toolkit';
import { createApiThunk } from "../utils/apiThunk";
import REDUX_STATUS from "../constants/reduxStatus";
import API_URL from "../constants/apiUrl";
import log from 'loglevel';

export const mileageListApi = createApiThunk('user/mileage', API_URL.MILEAGE_LIST, 'get');

const mileageSlice = createSlice({
    name: 'mileage',
    initialState: {
        status: 'idle',
        error: null,
        data: {},
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(mileageListApi.pending, (state) => {
                log.info('REDUX STATUS PENDING');
                state.status = REDUX_STATUS.LOADING;
            })
            .addCase(mileageListApi.fulfilled, (state, action) => {
                log.info('REDUX STATUS SUCCEEDED:', action.payload);
                state.status = REDUX_STATUS.SUCCEEDED;
                state.data = action.payload;
            })
            .addCase(mileageListApi.rejected, (state, action) => {
                log.info('REDUX STATUS FAILED:', action.payload.message);
                state.status = REDUX_STATUS.FAILED;
                state.error = action.payload.message;
            });
    },
});

export default mileageSlice.reducer;
