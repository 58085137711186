import React, { useEffect, useState, useRef } from 'react'
import styled, {createGlobalStyle} from "styled-components";
import siteConfig from '../../config/siteConfig.json'
import { mobile } from '../../utils/mediaQueries';
import { useNavigate } from 'react-router-dom';
import { randomNumbers } from '../../utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import LanguageSelector from '../../components/select/LanguageSelector';
import { UserInput } from '../../components/input/UserInput';
import Footer from '../../components/layout/Footer';
import { login } from '../../features/auth/authSlice';
import { IoMdRefreshCircle } from "react-icons/io";
import { TextSplitLine, pwEyes } from '../../components/utils/utils';
import Loading from '../../components/loading/Loading';
import STATUSES from '../../constants/reduxStatus';


const LoginContainer = styled.div`

  width: 100%;
  max-width: 460px;
  min-height: calc(100vh - ${p => p.$footerHeight}px);
  margin: auto;
  color: #9f9f9f;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${mobile`max-width: 100%;`}

  .inner {
    width: 100%;
    display: flex; flex-direction: column;
    justify-content: center;
    /* align-items: center; */

    ${mobile`padding: 0 20px;`}
    box-sizing: border-box;
  }

  .logo {
    margin-bottom: 20px;
    ${mobile`margin-bottom: 25px`}
    a {
      display: block;
      margin: 0 auto;
      width: 200px; height: 65px;
      ${mobile`
         width: 180px; height: 58px;
      `}
      /* padding: 0 70px; */
      
      box-sizing: border-box;
      img {
        width: 100%; height: 100%;
        
            
        

        
        
        /* max-width: 100%; */
      }
    }
  }

  .language {
    margin-bottom: 20px;
    ${mobile `margin-bottom: 10px;`}
  }

  .iptWrap{
    margin-bottom: 20px;

    &:last-child{
      margin-bottom: 0;
    }

    .user_input_id{
      margin-bottom: 15px;
      ${mobile `margin-bottom: 10px;`}
    }
  }


`;

const AuthCode = styled.div`

  margin-bottom: 20px;

  .tit {
    color: #5B5B5B;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.64px;

    padding-bottom: 10px;

  }
  .codeWrap {

    height: 50px;
    margin-bottom: 10px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.8);
    letter-spacing: -1px;
    text-align: center;
    display: flex; justify-content: center;
    align-items: center;
    font-weight: bold;
    position: relative;

    p {
      line-height: 32px;
      height: 32px;
      font-size: 32px; 
      display: flex; align-items: center;
      justify-content: center;
      margin-bottom: -3px;
      -webkit-user-select: none; /* Safari */
      -moz-user-select: none;    /* Firefox */
      -ms-user-select: none;     /* Internet Explorer/Edge */
      user-select: none;         /* 표준 문법 */
    }

    .refresh-btn{
      position: absolute;
      font-size: 24px; margin-right: 21px;
      right: 0; cursor: pointer;
      color: #16BFB6;
    }
  }
`;

const LinkWrap = styled.div`

  display: flex; align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 10px 0;
  letter-spacing: -0.04em;  
  line-height: 24px;

  .contact{
    display: flex;
    align-items: center;
    position: relative; top: -1px;
    color: #666;
    margin-bottom: 10px;
    ${mobile`margin-bottom: 12px;`}
    text-align: center;
    line-height: 24px;

    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: -0.64px;




    a{
      color: #aaa;
      font-family: Roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.64px;

      &:hover{
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .auth-page-btn{
    display: flex;
    align-items: center;
    /* height: 100%; */
    position: relative;
    white-space: nowrap;

    color: #AAA;
    font-family: Roboto;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 150% */
    letter-spacing: -0.64px;
  }

  a {
    color: #9f9f9f;
    position: relative;
  }

`;

const StyledButton = styled.button`

  width: 100%;
  height: 56px;
  ${mobile`height: 50px;`}
  margin-bottom: 10px;
  border-radius: 10px;
  background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);

  transition: all 0.3s;
  cursor: pointer;

  color: #FFF;
  text-align: center;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 150% */
  letter-spacing: -0.64px;

`;

const GlobalStyle = createGlobalStyle`
  body {
    background-image: url('img/bgimg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    
  }

`;

function Login() {

  const [isPwVisible, setIsPwVisible] = useState(false);

  const dispatch = useDispatch()

  const { status, error } = useSelector((state) => state.auth)


  const [userId, setUserId] = useState("")
  const [userPw, setUserPw] = useState("")
  const [authCode, setAuthCode] = useState("")

  const [currentCode, setCurrentCode] = useState(() => randomNumbers())

  const navigate = useNavigate();

  const currentLanguage = useSelector((state) => state.page.data.currentLanguage)

  // const [currentLanguage] = useState("kr");

  const handleLogin = async () => {

    // navigate('/tree')

    const reset = () => {
      setUserId("");
      setUserPw("");
      setAuthCode("");
      setCurrentCode(() => randomNumbers());
    }

    if (userId.length === 0) {
      alert("이메일을 입력하세요.");
      inputIdRef.current.focus();
    } else if (userPw.length === 0) {
      alert("패스워드를 입력하세요.");
      inputPwRef.current.focus();
    } else if (authCode.length === 0) {
      alert("인증코드를 입력해주세요.");
      inputCodeRef.current.focus();
    } else if (currentCode !== authCode) {
      alert("인증코드를 올바르게 입력해주세요.");
      inputCodeRef.current.focus();
    } else {

      const response = await dispatch(login({
        userId: userId, userPw: userPw
      }));

      if (response.error) {
        alert(response.payload.message); reset();
      } else {
        navigate('/dashBoard');
      }
    }
  }

  const [inputIdRef, setInputIdRef] = useState(null);
  const [inputPwRef, setInputPwRef] = useState(null);
  const [inputCodeRef, setInputCodeRef] = useState(null);

  const [isInputFocus, setInputFocus] = useState(false);

  const footerRef = useRef(null);
  const [footerHeight, setFooterHeight] = useState(0);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {

        const { height } = entry.contentRect;
        setFooterHeight( height );
      }
    });

    if (footerRef.current) {
      resizeObserver.observe(footerRef.current);
    }

    // Clean up the observer on component unmount
    return () => {
      if (footerRef.current) {
        resizeObserver.unobserve(footerRef.current);
      }
    };
  }, []);

  return (
    <>
      {status === STATUSES.LOADING && <Loading />}
        <GlobalStyle />
        <LoginContainer className="loginWrap" $footerHeight={footerHeight}>
        <div className="inner">
            <h1 className="logo">
                <a onClick={() => {
                  navigate('/')
                  // alert("준비중입니다.")
                }}>
                    <img src={process.env.PUBLIC_URL + "/img/espero_column_B.png"} alt="로고" />
                </a>
            </h1>
            <div className='language'>
              <LanguageSelector />
            </div>
            

            <div className="iptWrap">
                <UserInput
                    className="user_input_id" // siteConfig.email[currentLanguage]
                    placeholder={"E-mail"}
                    onChange={(value) => setUserId(value)}
                    value={userId}
                    getRef={(ref) => setInputIdRef(ref)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') handleLogin();
                    }}
                />
                <div className="psr" style={{position: 'relative'}}>
                    <UserInput
                        type={isPwVisible ? "text" : "password"}
                        name="userPassword"
                        placeholder={"Password"} // siteConfig.password[currentLanguage]
                        className="iptPwd"
                        onChange={(value) => setUserPw(value)}
                        value={userPw}
                        maxLength={20}
                        getRef={(ref) => setInputPwRef(ref)}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') handleLogin();
                        }}
                    >

                    {pwEyes(isPwVisible, () => setIsPwVisible(!isPwVisible))}

                    </UserInput>
                </div>
            </div>
            <AuthCode className="autoLoginCode">
                <p className="tit">
                    {siteConfig.autoLogin[currentLanguage]}
                </p>
                <div className="codeWrap">
                    <p>{currentCode}</p>
                    <IoMdRefreshCircle className='refresh-btn' onClick={() => {
                      setCurrentCode(() => randomNumbers());
                      setAuthCode("");
                    }}/>
                </div>
                {/* <!-- 인증코드를 입력하세요. --> */}
                <UserInput
                  className='auth-code-input'
                  type="text"
                  name="authCode"
                  maxLength={5}
                  onChange={(value) => setAuthCode(value)}
                  placeholder={siteConfig.codeIptMsg[currentLanguage]}
                  value={authCode}
                  getRef={(ref) => setInputCodeRef(ref)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') handleLogin();
                  }}
                  /> 
            </AuthCode>
            <StyledButton type="button" className="btnLogin" onClick={() => {
              handleLogin();
              // alert("준비중입니다.");
            }}> {/* onclick="auth_check()" */}
                {siteConfig.login[currentLanguage]}
            </StyledButton>
            <LinkWrap className="linkWrap">
              <div className='contact'>
                <p>Contact us: <a href="mailto:espero.info1@gmail.com">espero.info1@gmail.com</a></p>
              </div>
              <div className='auth-page-btn'>
                  <a onClick={() => {
                        navigate('/reset')
                        // alert("준비중입니다.")
                      }}>{siteConfig.findPwd[currentLanguage]}</a> 
                  <TextSplitLine />
                  <a onClick={() => {
                    navigate('/join');
                    // alert("준비중입니다.")
                  }}>{siteConfig.register[currentLanguage]}</a> 
              </div>
            </LinkWrap>
        </div>
        
    </LoginContainer>

    <div ref={footerRef}>
      <Footer/>
    </div>
    

    

    </>

  )
}

export default Login