import React from 'react'
import styled from 'styled-components'


const ModalOverlay = styled.div`
    width: 100%;
    height: 100%;
    background-color: #000000A6;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
`;

const ModalContainer = styled.div`
    position: relative;
    background-color: #222222;
    border-radius: 10px;
    padding: 0 30px 40px 30px;
    z-index: 51;
    @media (max-width:780px){
        padding: 0 20px 30px 20px;
    }
`

const ClostBtn = styled.div`
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    position: absolute;
    top: 15px; right: 30px;

    @media (max-width: 780px){
        top: 15px; right: 12px;
    }
`

const ModalTitle = styled.div`
    font-size: 28px;
    font-weight: 700;
    line-height: 32.81px;
    text-align: center;
    margin-top: 47px;
    color: #fff;

    @media (max-width:780px){
        font-size: 24px;
        margin-top: 35px;
        line-height: 28.13px;
    }
`

const ModalWrap = styled.div`

    overflow-y: auto;
    width: 580px;
    min-height: 300px;
    max-height: 703px;

    background-color: #fff;
    border-radius: 10px;
    padding: 40px 30px 25px 30px;
    margin-top: 20px;
    @media (max-width:780px){
        max-width: 280px;
        min-height: 300px;
        max-height: 475px;
        margin-top: 12px;
        padding: 20px;
    }

    &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
    }
    &::-webkit-scrollbar-thumb {
        background: #aaa;
        border-radius: 10px;
    }

    figure img{
        width: 100%;
    }
`

const AdditionalButtonWrap = styled.div`
    color: white;
    position: relative;
    transform: translateY(20px);
    display: flex; justify-content: space-between;
    flex-direction: row-reverse;

    @media (max-width:780px) {
        transform: translateY(16px);
    }
    
    button {
        color: white;
        font-size: 1rem;
        &:hover{
            text-decoration: underline;
        }
    }
    
`;

function Popup(props) {

    const { title="title", closeCallback, customButton=undefined } = props;

    return (
        <ModalOverlay>
            <ModalContainer>
                <ClostBtn onClick={closeCallback}>
                    <img src='./../img/common_close_w_btn.png' />
                </ClostBtn>
                <ModalTitle>{title}</ModalTitle>
                <ModalWrap className='modal_wrap'>
                    {props.children}
                </ModalWrap>
                <AdditionalButtonWrap>
                    {customButton}
                </AdditionalButtonWrap>
            </ModalContainer>
            
        </ModalOverlay>
    )
    }

export default Popup