import React, { useEffect, useState } from 'react';
import Header from '../components/layout/Header';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { boardListApi } from '../features/boardSlice';
import { useNavigate } from 'react-router-dom';

import Loading from '../components/loading/Loading'
import STATUSES from '../constants/reduxStatus'
import Footer from '../components/layout/Footer';
import { addCommasToNumber } from '../utils/utils';
import { useDeviceDetect } from '../components/utils/utils';
import Pagination from '../components/pagination/Pagination';

const LayoutBg = styled.div`
    min-height: 100vh;
    width: 100%;
    background-color: #FAFFF1;
    padding: 100px 20px 0px;
    @media (max-width:780px){
        padding: 60px 20px 0px;
    }
`

const MainLayout = styled.div`
    max-width: 1290px;
    margin: 0 auto;
`

const MainTitle = styled.div`
    padding-top: 110px;
    text-align: center;
    font-weight: 700;
    font-size: 2.125rem;
    line-height: 2.49rem;
    @media (max-width:780px){
        padding-top: 40px;
        font-size: 1.25rem;
        line-height: 1.465rem;
    }
`

const SearchInputWrap = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    img{
        width: 25px;
        height: 25px;
        position: absolute;
        top: 50%;
        right: 20px;
        cursor: pointer;
        @media (max-width:780px){
            width: 20px;
            height: 20px;
            right: 5px;
        }
    }
`

const SearchInput = styled.input`
    min-width: 295px;
    height: 45px;
    padding: 10px 20px;
    border: 1px solid #C4FF69;
    margin : 40px 0px 12px 0px;
    background-color: #FFFFFFD9;
    color: #222222;
    border-radius: 5px; 
    &:focus{
        outline: none;
        border-color: #16BFB6;
        background: #fff;
    }
    &::placeholder{
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: #6F6F6F;
    }
    @media (max-width:780px){
        margin : 30px 0px 10px 0px;
        padding: 5px;
        min-width: 0;
        width: 100%;
    }
`

const TableContainer = styled.div`
    width: 100%;
    border-top: 2px solid #666666;
    margin-bottom: 30px;
    @media (max-width:780px){
        margin-bottom: 20px;
    }
`

const TableRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 10px;
    height: 51px;
    border-bottom: 1px solid #D9D9D9;
    color: #666666;
    &.table_header div{
        font-weight: 700;
        font-size: 1.125rem;
        line-height: 1.318rem;
        color: #000000;
        cursor: default;
    }
    &.no-list{
        justify-content: center;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.172rem;
        height: 321px;
    }
    @media (max-width:780px){
        display: none;
    }
`

const TableRowNoList = styled.div`
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
    height: 321px;
    align-items: center;
    border-bottom: 1px solid #D9D9D9;
    color: #666666;
    @media (max-width: 780px){
        height: 300px;
    }
`

const TableNo = styled.div`
    max-width: 60px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.318rem;
`

const TableTitle = styled.div`
    max-width: 780px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
    color: #222222;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    cursor: pointer;
`

const FileIco = styled.div`
    width: 15px;
    height: 15px;
    img {
        width: 100%;
    }
`

const TableAuthor = styled.div`
    max-width: 120px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
`

const TableView = styled.div`
    max-width: 100px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
`

const TableDate = styled.div`
    max-width: 130px;
    width: 100%;
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
`

const MtableRow = styled.div`
    border-bottom: 1px solid #D9D9D9;
    padding: 12px 5px;
    min-height: 72px;
    @media (min-width:781px){
        display: none;
    }
`

const MtableRowTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; 
`

const MtableRowLeftTop = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

const MtableDate = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
    color: #666666;
    min-width: 85px;
`

const MtableView = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
    color: #666666;
    min-width: 84px;
    position: relative;
    &::after{
        position: absolute;
        content: "";
        width: 18px;
        height: 1px;
        background-color: #D9D9D9;
        transform: rotate(-90deg) translateY(-50%);
        top: 49%;
        left: -12px;
    }
`

const MtableAuthor = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
    color: #666666;
`

const MtableTitle = styled.div`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.172rem;
    color: #222222;
    margin-top: 10px;
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 7px;
`

const FooterContainer = styled.div`
    width: 100%;
    padding: 43px 0;
    position: relative;
    display: flex; 
    align-items: center;
    justify-content: center;
    p{
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.026rem;
        text-align: center;
        color: #AAAAAA;
    }

    @media (max-width:780px){
        padding: 32px 20px;
        position: ${(props)=>props.$nodata ? 'fixed' : 'relative'};
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
`

function Board() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { status, error, data } = useSelector((state) => state.board);
    // 페이지 번호
    const [pageNum , setPageNum] = useState(1);
    // 한 페이지에 보여줄 게시글 수
    const pageSize = 10;
    // 검색 키워드
    const [keyword , setKeyword] = useState('');
    const [mobileList, setMobileList] = useState([])

    // 검색 버튼 클릭 
    const handleSearch = () => {
        setPageNum(1);
        dispatch(boardListApi({ page: 1 , pageSize : pageSize, brdId : 1 , searchKeyword : keyword}));
    };

    // 제목 클릭 상세 이동
    const handleClick = (postId) => {
        navigate('/boardDetail', { state: { postId } });
    };

    // 페이지 변경
    const onChangePage = (page) => {
        if (page > 0 && page <= data?.totalPages) {
            setPageNum(page);
        }
    };

    const isMobile = useDeviceDetect();

    const fetchData = async (page) => {
        try {
            setPageNum(page);
            const result = await dispatch(boardListApi({ page: pageNum , pageSize : pageSize ,brdId : 1, searchKeyword: keyword}));
            const content = result.payload.content;
        
            if (page === 1) {
                setMobileList(content);
            } else {
                setMobileList(prevList => [...prevList, ...content]);
            }
        } catch (error) {
          console.log("마일리지 내역 불러오는 중 오류 발생", error);
        }
    };

    useEffect(() => { // 첫 로딩, pageNum 바뀔때 fetch
        fetchData(pageNum)
    }, [dispatch, pageNum]);

    useEffect(() => { // 기기 전환 될때마다 1페이지로 초기화
        setPageNum(1);
    }, [isMobile])

    return (
        <>
            {status === STATUSES.LOADING && <Loading />}
            <Header />
            <LayoutBg>
                <MainLayout>
                <MainTitle>공지사항</MainTitle>
                <SearchInputWrap>
                    <SearchInput
                        value={keyword}
                        onChange={(e)=>setKeyword(e.target.value)}
                        onKeyDown={(e) => {
                            if(e.key === "Enter") {
                            handleSearch();}   
                        }}
                        placeholder='검색어를 입력하세요.'
                    />
                    <img onClick={handleSearch} src='./../img/common_search_ico.png' />
                </SearchInputWrap>
                <TableContainer>
                    <TableRow className='table_header'>
                        <TableNo>NO</TableNo>
                        <TableTitle>제목</TableTitle>
                        <TableAuthor>글쓴이</TableAuthor>
                        <TableView>조회</TableView>
                        <TableDate>날짜</TableDate>
                    </TableRow>
                    {
                        data?.content && data?.content?.length > 0 ?
                        data?.content?.map((e,i)=>{
                            const postNumber = data?.totalElements - (pageNum - 1) * pageSize - i;
                            return(
                                <React.Fragment key={i}>
                                    <TableRow>
                                        <TableNo>{status == STATUSES.SUCCEEDED && postNumber}</TableNo>
                                        {
                                            e.postFile === 1 ?
                                            <TableTitle style={{textAlign:'left' , paddingLeft:'45px',display:'flex',alignItems:'center',gap:'8px'}} onClick={()=>handleClick(e.postId)}>
                                                <p>{e.postTitle || ''}</p>
                                                {
                                                    e.postFile === 1 &&
                                                    <FileIco>
                                                        <img src='./../img/file_ico.png' alt='file_ico' />
                                                    </FileIco>
                                                }
                                            </TableTitle>
                                            :
                                            <TableTitle style={{textAlign:'left' , paddingLeft:'45px'}} onClick={()=>handleClick(e.postId)}>
                                                <p>{e.postTitle || ''}</p>
                                            </TableTitle>
                                        }
                                        <TableAuthor>{e.userNickName || ''}</TableAuthor>
                                        <TableView>{addCommasToNumber(e.postHit)}</TableView>
                                        <TableDate>{e.postDateTime || ''}</TableDate>
                                    </TableRow>
                                </React.Fragment>
                            )
                        })
                        :
                        <TableRowNoList>작성된 내용이 없습니다.</TableRowNoList>
                    }
                    {   
                        isMobile && 
                        mobileList.map((e, i) => {
                            return (
                                <MtableRow key={i}>
                                    <MtableRowTop>
                                        <MtableRowLeftTop>
                                        <MtableDate>{e.postDateTime || ''}</MtableDate>
                                        <MtableView>조회 {addCommasToNumber(e.postHit)}</MtableView>
                                        </MtableRowLeftTop>
                                        <MtableAuthor>{e.userNickName || ''}</MtableAuthor>
                                    </MtableRowTop>
                                    <MtableTitle onClick={()=>handleClick(e.postId)}>
                                        <p>{e.postTitle || ''}</p>
                                        {
                                            e.postFile === 1 &&
                                            <FileIco>
                                                <img src='./../img/file_ico.png' alt='file_ico' />
                                            </FileIco>
                                        }
                                    </MtableTitle>
                                </MtableRow>
                            )
                        })
                    }
                </TableContainer>

                <Pagination
                    data={data?.content && data}
                    onChange={(num) => setPageNum(num)}
                    putPageNum={pageNum}
                />
                </MainLayout>
                <FooterContainer $nodata={data?.content?.length === 0}>
                    <p>© 2024 ESPERO. All rights reserved.</p>
                </FooterContainer>               
            </LayoutBg>
        </>
    )
}

export default Board