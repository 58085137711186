import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export default function UrlRefresh() {
    const location = useLocation();

    let currentUrl = useRef("");

    useEffect(() => {
        if (currentUrl.current === location.pathname) {
            window.location.reload();
        } else {
        currentUrl.current = location.pathname;
        };
        
    }, [location]);

    return null;
}