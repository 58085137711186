
import React, { useEffect, useState, useCallback } from 'react';

export const randomNumbers = () => {

    let randomNumbers = ""

    for (let i = 0; i < 5; i++) {
        const random = Math.floor(Math.random() * 10);
        randomNumbers += random
    }

    return randomNumbers
}

export function useOutsideClick(callback, refs) {

  const [isInside, setIsInside] = useState(false);

  const clickOutsideCheck = useCallback((e) => {
    const clickCheck = refs.some(ref => {
      return ref.current && ref.current.contains(e.target) // 하나라도 true면 인사이드 클릭
    })
    // console.log(clickCheck)
    setIsInside(clickCheck)
  }, [refs, setIsInside])

  useEffect(() => {

    const clickOutside = () => {
      if (!isInside) {
        callback()
      }
    }

    document.addEventListener('mousedown', clickOutsideCheck) // 클릭해서 갑자기 사라지는 요소가 있으면 문제생김 mousedown할때 미리 검사하기
    document.addEventListener('mouseup', clickOutside)

    return () => {
      document.removeEventListener('mousedown', clickOutsideCheck)
      document.removeEventListener('mouseup', clickOutside)
    }

  }, [callback, clickOutsideCheck, isInside])

}

export const addCommasToNumber = (number) => {
  if (number === undefined || number === null) {
    return '0.0000';
  }

  const findDot = number.toString().includes('.');

  if (findDot) {
    let [jeongsu, sosiuuu] = number.toString().split('.');

    // 소수점 이하 4자리로 맞추기
    sosiuuu = sosiuuu.padEnd(4, '0');

    return jeongsu.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '.' + sosiuuu;
  }

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
