import React, { useState, useEffect } from 'react'
import styled, { createGlobalStyle } from 'styled-components';
import { Divider } from '../components/utils/utils';
import { pwEyesAuth } from '../components/utils/utils';
import Footer from '../components/layout/Footer';
import { mobile, custom } from '../utils/mediaQueries';
import { AuthInput } from '../components/input/AuthInput';
import Header from '../components/layout/Header'
import Popup from '../components/layout/Popup'
import { useDispatch, useSelector } from 'react-redux';
import { nftApplyApi, nftPageApi, nftPurchaseApi } from '../features/nft/nftSlice';
import { addCommasToNumber } from '../utils/utils';
import { useNavigate } from 'react-router-dom';
import Loading from '../components/loading/Loading'
import STATUSES from '../constants/reduxStatus'

const PageLayout = styled.div`

  width: 1290px;
  padding: 0;
  margin: 0 auto;
  box-sizing: border-box;

  display: flex; flex-direction: column;
  justify-content: space-between;
  font-size: 18px;
  margin-top: 100px;

  ${custom(1290, `
      width: 100%;
      max-width: calc(1290px + 40px);
      padding: 0 20px;
      box-sizing: border-box;
  `)}

  ${mobile `
    padding: 0 20px;
    font-size: 14px;
    margin-top: 60px;
  `}

  .order_request_container_wrap {
    width: 100%;
  }

  .order_request_title h1 {
    text-align: center;
    font-size: 34px;
    font-weight: 700;
    color: #000;

    margin: 110px 0 40px 0;
    ${mobile`
        margin: 40px 0 30px 0;
        font-size: 20px;
        font-weight: 700;
    `}
  }

  h3.title {
    margin: 40px 0 16px 0;
    font-weight: 700;
    font-size: 18px;

    font-family: Roboto;

    ${mobile`
        font-size: 18px;
        margin: 30px 0 10px 0;
    `}
  }

  .user_input_wrap .user_input {
    max-width: 460px;
    ${mobile`max-width: 100%;`}
  }

  /* .user_input_wrap h3 {
    font-size: 1.5rem;
    ${mobile`font-size: 1rem;`}
  } */

  .send_btn {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    padding: 12px 20px;
    background-color: #36324a;
    cursor: pointer;
    margin-left: 10px;
  }

  .submit_btn {
    display: flex;
    justify-content: center;
    margin: 20px auto;
    width: 460px; height: 56px;
    ${mobile`
        width: 100%; height: 50px;
        margin: 20px auto 11px auto;
    `}

    button {
      cursor: pointer;
      border: none;
      border-radius: 10px;
      width: 460px;
      ${mobile`width: 100%;`}
      padding: 15px 0;
      text-align: center;
      background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);
      color: #fff;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .agree{

        padding: 12px 0 0 0;
        font-size: 16px;
        font-weight: 700;
        text-align: left;

        .agree_inner{
            
            display: flex; align-items: center;
            text-decoration: underline;
        }

        ${mobile`
            padding: 10px 0;
            font-size: 14px;
        `}

        img{
            cursor: pointer;
            width: 21px; height: 21px;
            padding-right: 12px;
            box-sizing: content-box;
        }

        span{
          cursor: pointer;
        }
    }
`;

const InputLayout = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px;
  min-height: 69px;

  &.select_nft_wrap{
    ${mobile`height: 81px;`}
  }

  &.input_password{
    ${mobile`padding: 12.2px 5px;`}
  }

  ${mobile`
    padding: 12px 5px;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    font-size: 14px;
    min-height: 70px;
  `}

  .title {
    display: flex;
    align-items: center;
    width: 200px;
    margin-right: 20px;
    color: #222;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    vertical-align: baseline;
    text-align: left;

    ${mobile`margin: 0 0 8px 0;`}
  }

  .content{

    color: #222;

    font-size: 16px;
    font-weight: 700;
    text-align: left;

    .unit{
      margin-left: 12px;
      ${mobile`margin-left: 8px;`}
    }

  }

  .select_nft{
    /* padding-bottom: 12px; */
    
    button{
        width: 83px; height: 32px;
        ${mobile`height: 30px;`}
        border-radius: 20px;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        margin-right: 11px;
        color: #aaa;
        background-color: #d9d9d9;
        cursor: pointer;
        &.active{
            background-color: #16BFB6;
            color: white;
        }
    }
  }
`;

const PopupContents = styled.div`

.section_title{
  margin-bottom: 10px;
}

.row{
  display: flex; align-items: center;
  height: 61px;
  font-size: 16px;
  font-weight: 700;
  padding: 0 3px;

  font-family: Roboto;


  ${mobile`
    height: 70px;
    flex-direction: column;
    justify-content: center;
    gap: 8px;

    .row_name{
      width: 100% !important;
    }

    .row_content{
      width: 100%;
    }
  `}

  .row_name{
    width: 192px;
    color: #222;
  }

  .row_content{
    color: #5b5b5b;

    .unit{
      margin-left: 12px;
      ${mobile`margin-left: 8px;`}
    }
  }
}

.caution{
  display: flex;
  color: #FF0E0E;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  padding: 0 3px;

  .caution_text{
    display: flex;
    ${mobile`display: block;`}
  }

  p{margin-right: 0.25rem;}
}

.submit_btn {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 56px;
  margin-top: 30px;


  ${mobile`
      width: 100%; height: 50px;
      
  `}

  button {
    cursor: pointer;
    border: none;
    width: 100%;
    border-radius: 10px;
    ${mobile`width: 100%;`}
    padding: 15px 0;
    text-align: center;
    background: linear-gradient(90deg, #16BFB6 0%, #2AE4A1 100%);
    color: #fff;
    font-size: 16px;
    font-weight: 700;

    font-family: Roboto;
    letter-spacing: -0.04em;

  }
}
`;

const PopupWrap = styled.div`
.modal_wrap{
  padding: 30px 30px 25px 30px;

  ${mobile`
    padding: 26px 15px 20px 15px;
  `}
}
`;

const GlobalStyle = createGlobalStyle`
    body {
        background-color: #FAFFF1;
    }

    .App{
        min-height: 100vh;
        display: flex; flex-direction: column;
        justify-content: space-between;
    }
`;


function OrderRequest() {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { status } = useSelector((state) => state.nft);
    const { totalOwned, product } = useSelector((state) => state.nft.data);
    const totalOwned1 = (totalOwned ?? 0).toFixed(4)

    useEffect(() => { // 기본값
      const nftListLoad = async () => {

        const response = await dispatch(nftPageApi())

        // 기본 선택 nft는 vip로 해야됨
        const vipProduct = response.payload.product.find((nft) => nft.productName === 'Vip')
        setUserData({
          productName: vipProduct.productName,
          productPrice: vipProduct.productPrice
        })
      }

      nftListLoad();
    }, [dispatch])

    const [isOpenPopup, setOpenPopup] = useState(false);
    const [pwVisible, setPwVisible] = useState(false);
    const [pwRef, setPwRef] = useState(null);
    const [popupData, setPopupData] = useState({
      afterPoint: 0,
      productName: "",
      productPrice: 0
    })

    const [userData, setUserData] = useState({
      productName: '',
      productPrice: 0,
      tradePw: '',
      termsAgree: 'N'
    });

    const handleAgree = () => {
        setUserData({
            ...userData, termsAgree: userData.termsAgree === "Y" ? "N" : "Y"
        })
    }

    const handleSubmit = async () => {

      if (userData.tradePw === "") {
        pwRef.current.focus();
        alert("거래 비밀번호를 입력하세요.");

      } else if (!/^\d{6}$/.test(userData.tradePw)) {
        pwRef.current.focus();
        alert("거래 비밀번호는 6자리 숫자로 입력하세요.")

      } else if (userData.termsAgree !== "Y") {
        alert("구매계약 신청에 체크해주세요.")

      } else if (userData.productPrice > totalOwned){
        alert("포인트가 부족합니다.")
      } else {

        const response = await dispatch(nftApplyApi({
          productName : userData.productName
        }))

        // console.log('apply : ', response)
        if (response.error) {
          // nothing
        } else {
          setOpenPopup(true)
        }
      }
    };

    const handleFinalSubmit = async () => {

        const response = await dispatch(nftPurchaseApi({
            productName : userData.productName,
            productPrice : userData.productPrice,
            tradePw : userData.tradePw,
            termsAgree: userData.termsAgree
        }))

        // console.log('purchase : ', response)

        if (response.error){
          // 노 액션..
        } else {
          // 구매 확정..
          alert("구매가 완료되었습니다.")
          navigate('/order-history')
        }
        
        // else {
        //   console.log('구매 확정 실패')
        // }
    }

    // useEffect(() => {

    //   console.log('totalOwned : ', totalOwned)
    //   console.log('product : ', product)
    //   console.table('userData : ', userData)

    // }, [totalOwned, product, userData])


    // useEffect(() => {
    //   console.log('userData : ', userData)
    // }, [userData])


  return (
    <>
    {status === STATUSES.LOADING && <Loading />}
    <GlobalStyle />
    <Header />
    { // 팝업
      isOpenPopup &&
        <PopupWrap>
        <Popup title='NFT 구매 유의사항' closeCallback={() => setOpenPopup(false)}>
          <PopupContents>
            <h3 className='section_title'>NFT 구매 신청</h3>
            <Divider $isThick={true}/>
            <div className='row'>
              <div className='row_name'>NFT</div>
              <div className='row_content'>{userData && userData.productName}</div>
            </div>
            <Divider/>
            <div className='row'>
              <div className='row_name'>NFT 구매수량</div>
              <div className='row_content'>
                {userData && addCommasToNumber(userData.productPrice)}
                <span className='unit'>ESP</span>
              </div>
            </div>
            <Divider/>
            <div className='row'>
              <div className='row_name'>NFT 구매 후 보유 ESP</div>
              <div className='row_content'>
                {totalOwned && userData && addCommasToNumber(totalOwned - userData.productPrice)}
                <span className='unit'>ESP</span>
              </div>
            </div>
            <Divider/>
            <div className='caution'>
              <p className='caution_icon'>*</p>
              <div className='caution_text'>
                <p>구매 후 되돌릴 수 없습니다.</p>
                <p>그래도 구매하시겠습니까?</p>
              </div>
            </div>
            <div className='submit_btn'>
                <button className='' onClick={handleFinalSubmit}>
                    구매하기
                </button>
            </div>
          </PopupContents>
        </Popup>
      </PopupWrap>
    }
    


    <PageLayout className='order_request_container'>
        <div className='order_request_container_wrap'>
            <div className='order_request_title'>
                <h1>NFT 구매신청</h1>
            </div>
            <div className='user_input_wrap'>

                <h3 className='title'>NFT 구매</h3>

                <Divider $isThick={true} />

                <InputLayout>
                    <div className="title">보유수량</div>
                    <div className='content'>
                    {totalOwned && addCommasToNumber(totalOwned1)}
                    <span className='unit'>ESP</span></div>
                </InputLayout>

                <Divider $isThick={false} />


                <InputLayout className='select_nft_wrap'>
                    <div className="title">NFT 선택</div>
                    <div className='select_nft'>

                      {/* sort는 원본 배열 훼손함 */}

                      {userData && [...product].sort((a, b) => a.id - b.id).map((p, idx) => {
                            return (
                              <button
                                key={idx}
                                className={userData.productName === p.productName ? 'active' : ''}
                                onClick={() => setUserData({...userData, productName: p.productName, productPrice: p.productPrice})}
                              >
                                {p.productName === "Vip" ? "VIP" : p.productName}
                              </button>
                            )
                      })}

                        {/* <button className={userData.productName === 'member' ? 'active' : ''} onClick={() => {
                            setUserData({...userData, productName: 'member'});
                        }}>Member</button>
                        <button className={userData.productName === 'vip' ? 'active' : ''} onClick={() => {
                            setUserData({...userData, productName: 'vip'});
                        }}>VIP</button> */}
                    </div>
                </InputLayout>

                <Divider $isThick={false} />

                <InputLayout>
                    <div className="title">NFT 구매수량</div>
                    <div className='content'>
                      {userData && addCommasToNumber(userData.productPrice)}
                      <span className='unit'>ESP</span>
                    </div>
                </InputLayout>

                <Divider $isThick={false} />

                <h3 className='title'>인증</h3>

                <Divider $isThick={true} />

                <InputLayout className='input_password'>
                    <div className="title">거래 비밀번호</div>
                    <AuthInput
                        className="user_input"
                        type={pwVisible ? "text" : "password"}
                        placeholder="거래 비밀번호 입력"
                        onChange={(value) => setUserData({
                            ...userData, tradePw: value
                        })}
                        maxLength={6}
                        getRef={(ref) => setPwRef(ref)}
                    >
                        {pwEyesAuth(pwVisible, () => setPwVisible(!pwVisible), 
                      {width: '20px', padding: '7.5px 10px', boxSizing: 'content-box'}
                      )}

                    </AuthInput>
                </InputLayout>

                <Divider $isThick={false} />

                <div className='agree'>
                    <div className='agree_inner'>
                        {
                        userData && userData.termsAgree === "Y" ?
                            <img onClick={handleAgree} src='img/common_ico_check_type1_on@2x.png' />
                            :
                            <img onClick={handleAgree} src='img/common_ico_check_type1_off@2x.png' />
                        }
                        <span className='agree_text' onClick={handleAgree}>
                          NFT 구매계약을 신청합니다.
                          </span>
                    </div>
                </div>
            </div>
            <div className='submit_btn'>
                <button className='' onClick={handleSubmit}>
                    구매
                </button>
            </div>
        </div>

        
    </PageLayout>
    <Footer style={{marginTop: 'auto'}}/>

    </>
  )
}

export default OrderRequest